@import 'src/styles/colorVars.scss';

.wrapper {
    display: flex;
    align-items: center;

    [class*="ant-space"] {
      width: 100% !important;
    }
}

.button {
    width: 100%;
    margin: 0 16px;
    height: 40px !important;
}

.title {
    display: flex;
    justify-content: center;
    margin: 24px 0;
}

.menu {
    position: absolute;
    top: 72px;
    left: 0px;
    right: 0px;
    display: flex;
    flex-direction: column;
    background: white;
    border: 1px solid #eaeaea;
    z-index: 15;
}

.radio {
    display: flex !important;
    align-items: center !important;
    width: 100%;
    gap: 12px;
    padding: 8px 24px !important;
    height: 40px !important;
    transition: background-color 0.15s ease-in-out;

    &:hover {
        background-color: $secondary_grey;
    }

    [class*="ant-radio"] {
      top: 0;
    }

    [class='ant-radio-inner'] {
        width: 24px !important;
        height: 24px !important;

        &:after {
            width: 24px !important;
            height: 24px !important;
            margin-top: -12px !important;
            margin-left: -12px !important;
            border-radius: 50%;
        }
    }

    :global {
        .ant-radio-checked .ant-radio-inner {
            border-color: $primary_grey !important ;
        }

        .ant-radio-checked .ant-radio-inner:after {
            background-color: $primary_green;
        }

        .ant-radio:hover .ant-radio-inner {
            border-color: $primary_green;
        }

        .ant-radio-checked .ant-radio-inner:focus {
            border-color: $primary_grey;
        }
    }
}

.inputWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
}

.input {
    width: 80px !important;
    height: 40px;
    margin-right: 10px !important;
}

.input:hover {
    border-color: $primary_green !important;
}

.input:focus {
    border-color: $primary_green !important;
    box-shadow: none !important;
}

.buttonsWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 32px;

    button {
        width: 100%;
        min-width: auto;
        border-radius: 0;
    }
}