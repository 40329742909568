@import "src/styles/numbers";
@import "src/styles/colorVars";

.hiddenTextbox {
  display: none !important;
}

.addNoteButton {
  cursor: pointer;
  position: relative;
  width: 84px;
  height: 24px;
  margin: 8px;
  border: none;
  border-radius: 4px;
  font-size: 11px;
  color: $primary_white;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--background-color);

  &:hover {
    background: var(--background-color-hover);
  }
}

.textbox {
  display:flex;
  position:relative;
  flex-direction: column;
  height: auto;
  align-items: flex-end;

  border: 1px solid $primary_grey;
  border-radius: 2px;
  margin-bottom: 24px;
}
