@import "src/styles/colorVars";
@import "src/styles/numbers";

.video-message-wrapper {
  width: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  align-items: center;
  gap: 4px;
  margin-bottom: 4px;

  &.small {
    max-width: 90px;

    div:first-child {
      min-height: 90px;
      min-width: 90px;
    }
    video {
      height: 90px;
      max-width: 90px;
    }
    .video-message-caption {
      max-width: 90px;
    }
    .placeholderContainer, .placeholderContainer svg {
      width: 90px;
      height: 90px; 
    }
  }

  &.medium {
    max-width: 200px;

    div:first-child {
      min-height: 200px;
      min-width: 200px;
    }
    video {
      height: 200px;
      max-width: 200px;
    }
    .video-message-caption {
      max-width: 200px;
    }
    .placeholderContainer, .placeholderContainer svg {
      width: 200px;
      height: 200px;
    }
  }

  &.large {
    max-width: 300px;

    div:first-child {
      min-height: 300px;
      min-width: 300px;

      @media (max-width: $mobile_sm_width) {
        min-height: 200px;
        min-width: 200px;
      }
    }
    video {
      height: 300px;
      max-width: 300px;

      @media (max-width: $mobile_sm_width) {
        height: 200px;
        max-width: 200px;
      }
    }
    .video-message-caption {
      max-width: 300px;

      @media (max-width: $mobile_sm_width) {
        max-width: 200px;
      }
    }
    .placeholderContainer, .placeholderContainer svg {
      width: 300px;
      height: 300px;

      @media (max-width: $mobile_sm_width) {
        width: 200px;
        height: 200px;
      }
    }

    @media (max-width: $mobile_sm_width) {
      max-width: 200px;
    }
  }


  div:first-child {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;

    @media (max-width: $mobile_sm_width) {
      min-height: 200px;
      min-width: 200px;
    }
  }

  video {
    display: block;
    width: 100%;
    object-fit: cover;
    position: absolute;
    cursor: pointer;
    border-radius: 4px;
  }

  &--loading {
    video {
      display: none;
    }

    &.small {
      min-width: 100px;
    }
    &.medium {
      min-width: 200px;
    }
    &.large {
      min-width: 300px;

      @media (max-width: $mobile_sm_width) {
        min-width: 200px;
      }
    }

    div:first-child {
      background-color: $primary_grey;
      border-radius: 4px;
      opacity: 0.7;
    }
  }
}

.video-message-caption {
  align-self: flex-start;
  line-height: 16px;

  &--incoming {
    color: $primary_black;
  }
}

.play-button {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 48px;
  height: 48px;
  cursor: pointer;
  pointer-events: none;
}

.loading-icon {
  svg {
    height: 48px;
    width: 48px;
  }
}

.placeholderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  
  svg {
    border-radius: 4px;
  }
}