@import "src/styles/numbers";
@import "src/styles/colorVars";

.resultsList {
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100%;

  &.hideFavorites [class="favorite"] {
    display: none;
  }
}

.resultsListLong {
  height: calc(100% - 72px);
}

.filtersContainer {
  display: flex;
  flex-direction: column;

  &.withBorder {
    border-bottom: 1px solid $antd_border;
  }
}

.filtersBlock {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 12px 0;
  gap: 12px;
  padding: 0 16px;
  min-width: 100%;

  & > div {
    min-width: 100%;
    width: 100%;
  }
}

.resultsListTitle {
  padding: 0px 10px;
  margin: 12px 0;

  font-family: "SF UI Text","Segoe UI";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */
  letter-spacing: -0.02em;

  color: #000000;

}

.resultsListSearchSectionTitle {

  padding: 0 10px;
  margin: 0 12px;

  font-family: "SF UI Text";
  font-size: 12px;
  line-height: 16px;
  /* identical to box height, or 133% */
  letter-spacing: -0.02em;
  text-transform: uppercase;

  color: #546BD6;
}

.resolvePendingChatsButton {
  width: 92%;
  margin: 0 16px;
}

.resolvePendingChatsButton:hover {
  border-color: #0FB878 !important;
}

.headerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  color: $primary_black;
  font-size: 14px;
  font-weight: bold;
}

.dropdownHeader {
  padding: 8px;
  padding-bottom: 16px;
  font-weight: bold;

  span {
    color: $primary_red
  }
}

.dropdownItemWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  text-transform: capitalize;
  padding: 0 8px;
}

@media (max-width: $mobile_width) {
  .resultsList {
    width: 100vw;
    scrollbar-width: 0px;
    -ms-overflow-style: none;
    height: 100%;
    padding-top: 16px;
  }
  .resultsList::-webkit-scrollbar {
    display: none;
  }
  .filtersBlock {
    width: 100vw;
  }
}

.clearAllButton {
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 12px;

  svg {
    width: 16px;
    height: 16px;
  }
}

.channelSelect {
  max-width: $chatsList_width;
}

.channelSelectMobile {
  overflow: hidden;

  [class="ant-select-selector"] {
    height: 32px !important;
  }
}

.timestamp {
  letter-spacing: 0;
  font-size: 11px;
  color: $primary_black;
  display: flex;
  align-items: center;
}

.favoriteChatsList {
  margin-bottom: 24px;
  border-bottom: 1.5px solid $antd_border;
}