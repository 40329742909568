@import "src/styles/colorVars";

.replyMessage {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 16px;
    align-items: center;
    flex: 100%;
    padding: 4px 16px;
    margin: 12px;
    margin-top: 0;
    background-color: $primary_white;
    border: 1px solid #eeeef1;
    border-left: 4px solid $primary_green;
    max-height: 72px;
    overflow: hidden;
    width: 100%;

    &.incoming {
        border-left-color: $primary_blue;
    }
}

.replyMessageContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
    height: 100%;
}

.replyMessageHeader {
    font-size: 14px;
    font-weight: 700;
    color: $primary_green;
}

.replyMessageBody {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.closeReplyButton {
    cursor: pointer;

    svg {
        width: 16px;
        height: 16px;
        fill: $tertiary_black;
    }
}
