.messageContainer {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 4px;
}

.subjectContainer {
    display: flex;
    font-size: 16px;
    font-weight: bold;
}