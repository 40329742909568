@import 'src/styles/colorVars';
@import 'src/styles/numbers';

.container {
    display: grid;
    grid-template-rows: auto auto 1fr;
}

.header {
    padding: 16px 24px;
    border-bottom: 1px solid $primary_grey_border;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 72px;
    min-height: 72px;
}

.title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    font-size: 20px;
}

.headerIcon {
    pointer-events: none;

    svg {
        display: flex !important;
        align-items: center;
        position: relative;
        top: 1px;

        path {
            fill: $primary_black;
        }
    }
}

.listContainer {
    overflow: auto;
    min-height: 200px;
}

.closeModal {
    display: flex;
    align-items: center;

    svg {
        width: 20px;
        height: 20px;
    }
}
