@import 'src/styles/numbers';

.contactFilterHeader {
    display: grid;
    grid-template-columns: 1fr auto;
    justify-content: space-between;
    gap: 24px;
    padding: 16px;
    overflow: hidden;
    min-height: 64px;

    &.rtl {
        direction: rtl;
    }

    @media (max-width: $mobile_width) {
        gap: 8px;
    }
}

.contactFilterIcon svg {
    height: 20px;
    width: 20px;
    margin-right: 4px;
}

.contactFilterItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;

    &.departmentItem {
        width: 196px;

        @media (max-width: $mobile_width) {
            width: 160px;
        }
    }
}

.activeChatLabel {
    display: flex;
    align-items: center;
    max-width: 96px;
    line-height: 16px;
    text-align: right;

    &.rtl {
        text-align: left;
    }

    @media (max-width: $mobile_width) {
        font-size: 13px;
    }
}
