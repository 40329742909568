.text {
    font-family: SF Pro Text,"Segoe UI";
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    line-height: 10px;
    text-align: right;
    letter-spacing: 0.5px;
    white-space: nowrap;
    text-transform: uppercase;
    align-self: flex-start;
    color: #fff;
    padding: 0.2rem 0.5rem;
    border-radius: 1rem;
}
