@import "src/styles/colorVars";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.divider {
  margin: 0 !important;
}

.text {
  letter-spacing: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  word-wrap: break-word;
  position: relative;
  color: $primary_black;
  margin: 12px;
  text-transform: capitalize;

  &.whiteText {
    color: $primary_white;
  }
}

.buttonText {
  color: $primary_green !important;

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.contactsButton {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  cursor: pointer;
}

.contactsList {
  width: 100%;
}

.nameWrapper {
  display: flex;
  align-items: center;
  margin: 8px 0;
}

.avatar {
  width: 41px;
  height: 41px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 4px;
}
