@import "src/styles/colorVars";

.secondPageWrapper {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 60px;
    position: relative;

    &.rtl {
        direction: rtl;
    }
}

.bulkAdvandesSettingsWrapper {
    padding: 0 1rem;
}

.csvWizardAdvancedDescriptionBlock {
    display: grid;
    grid-template-columns: auto 1fr;
    padding: 12px;

    &.rtl {
        text-align: right;
        direction: rtl;

        .wizard-info-circle {
            margin-left: 8px;
            margin-right: 0;
        }
    }
}

.bulkWizard {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 1.4;
    letter-spacing: -0.02em;
    margin: 0;
    color: #4C5250;

    &.ellipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.wizard-info-circle {
    margin-right: 7px;
    margin-top: 2px;
}
