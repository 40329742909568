@import 'src/styles/colorVars';
@import 'src/styles/numbers';

.container {
    display: grid;
    grid-template-columns: 1fr;
}

.buttonsWrapper {
    position: relative;
}

.buttonsBody {
    padding: 0px 10px;
    right: 0;
    position: relative;
    width: 100%;
    margin-bottom: 0;
    letter-spacing: 0;
    font-size: 14px;
    font-weight: 500;
    word-wrap: break-word;
    white-space: pre-wrap;
    word-break: break-word;
    color: $primary_white;

    &.rtl {
        direction: rtl;
    }
}

.timestampContainer {
    padding: 0px 10px;
    right: 0;
}

.receiver {
    position: relative;
    float: right;
    margin-bottom: 2px;
    width: 100%;
    bottom: -4px;
    right: -2px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.timestampUser {
    letter-spacing: 0;
    position: relative;
    bottom: 0px;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    word-wrap: break-word;
    margin-bottom: 0;
    width: 70px;
    right: 3px;
    color: $primary_white;

    display: flex;
    justify-content: flex-end;
}
