.wrapper{
    border-radius: 2px;
    box-sizing: border-box;
    padding: 4px;
    border: 1px solid #DEDEDE;
    background-color: #FDFDFD;
    z-index: 100;
}

.customPicker {
    height: 240px;
}

.colorsWrapper{
    display: grid;
    box-sizing: border-box;
    padding: 12px 18px 18px 18px;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 12px;
}
.colorItem{
    border-radius: 2px;
    width: 100%;
    height: 24px;
    cursor: pointer;
}
.colorItem:hover{
    transform: scale(1.2);
}

.noColorItem {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #eeeeee;

    svg {
        width: 16px;
        height: 16px;
    }
}

.colorIndicator{
    width: 80px;
    height: 40px;
    background-color: white;
    border-radius: 2px;
    border: 1px solid #DEDEDE;
    display: flex;
    align-items: center;
    justify-content: center;
}

.inputColor{
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.openIcon{
    margin: 0 22px;
}
.picker{
    margin: 0 auto;
}

.tabSpacer {
    margin: 32px;
}
