@import "src/styles/colorVars.scss";

.container {
  display: flex;
  flex-direction: row;
  gap: 12px;
  background-color: $secondary_green;
  padding: 16px;
  border-radius: 2px;
  margin-bottom: 12px;
}

.icon {
  position: relative;
  top: 2px;

  svg {
    fill: $primary_green;
  }
}

.whatsText {
  color: $primary_green;
  font-weight: 600;
}
