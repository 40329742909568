.modalContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 35vh;
}

.modalHeader {
  height: 80px;
  min-height: 80px;
  width: 100%;
  border-bottom: 2px solid #DEDEDE;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 25px;
}

.closeIconWrapper {
  width: 32px;
  height: 32px;
  border-radius: 2px;
  text-align: center;
  line-height: 32px;
  transition: 500ms;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.closeIconWrapper:hover {
  background: #F5F5F5;
}

.modalContentText {
  padding: 20px;
}

.modalBottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  z-index: 1;
  box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.12);
  border-radius: 2px 2px 0 0;
  padding: 20px;
  width: 100%;
}

.modalButton {
  width: 140px;
}

.modalButton:hover {
  border-color: #0FB878 !important;
  box-shadow: none !important;
  color: #0FB878 !important;
}
