.user-item-label {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    font-size: 12px;
    white-space: nowrap;
}

.user-item-label__square {
    width: 8px;
    height: 8px;
    border-radius: 1px;
}
