@import "src/styles/numbers";
@import "src/styles/colorVars";

.closeManagerWrapper {
    width: 24px;
    height: 24px;
    line-height: 24px;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.closeManagerWrapper:hover {
    background: #F5F5F5;
}

.managerContents {
    position: absolute;
    width: 100%;
    height: 80vh;
    background-color: white;

    display: flex;
    flex-direction: column;
    z-index: 2;
    
    &.rtl {
        direction: rtl;

        .title::after {
            left: unset;
            right: 24px;
        }

        .entityListItem {
            border-left: 0;
            border-right: transparent 2px solid;
            &:hover {
                border-right-color: #21AE84;
            }   
        }
    }
}

.header {
    height: 80px;
    width: 100%;
    border-bottom: 2px solid #DEDEDE;

    letter-spacing: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    word-wrap: break-word;
    position: relative;
    margin-top: 0;
    margin-bottom: 0;
    color: #353535;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    padding: 0 24px;
}

.title {
    display: flex;
    align-items: center;
    gap: 12px;
}

.title::after {
    content: '';
    border-bottom: 2px solid #000000;
    width: 56px;
    left: 24px;
    position: absolute;
    display: none;
    top: 78px;
}

.titleGreen::after {
    border-bottom-color: #30C498;
    display: block;
}

.titleRed::after {
    border-bottom-color: #E26565;
    display: block;
}

.listRoot {
    background-color: white;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: calc(100% - 80px);
}

.listContents {
    height: calc(100% - 86px);

    overflow-x: hidden;
    overflow-y: scroll;
}

.listActions {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 2px solid #EAEAEA;
    padding: 32px 20px;
}

.deleteWrapper {
    background-color: white;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: calc(100% - 80px);
}

.deleteEditorWrapper {
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    height:100%;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 2rem;
}

.deleteEntityHolder {
    background-color: #F5F5F5;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
}

.deleteEntityQuestion {
    overflow-wrap: break-word;
    height: 100%;
    display: flex;
    align-items: center;
}

.operationCancelButton, .operationContinueButton {
    min-height: 48px;
    height: 48px;
    width: 168px;
    border: 1px solid #EAEAEA;
    z-index: 1;
}

.operationCancelButton span, .operationContinueButton span{
    font-size: 13px;
}

/* ****************************************** EDIT */

.editFormWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: calc(100% - 80px);
}

.editForm {
    padding: 20px 50px;

    height: calc(100% - 86px);

    overflow-x: hidden;
    overflow-y: scroll;

    @media (max-width: $mobile_width) {
        padding: 20px 16px;
    }
}

.formBottom {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 2px solid #EAEAEA;
    padding: 24px 20px;
    justify-content: flex-end;
    gap: 24px;

}

.entityListItem {
    letter-spacing: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    word-wrap: break-word;
    position: relative;
    margin-bottom: 0;
    color: #353535;
    height: 68px;

    border-left: transparent 2px solid;
    border-bottom: 1px solid #EAEAEA;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 0 40px;

    @media (max-width: $mobile_width) {
        padding: 0 16px;
    }
}

.entityTeaser {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
}

.entityListItem:hover {
    border-left: #21AE84 2px solid;
    background-color: #F8F8F8;
}

.entityListItem:hover span {
    color: #636766;
}

.disabled  {
    color:rgba(0, 0, 0, 0.12);
}

.disabled:hover {
    color:rgba(0, 0, 0, 0.12);
    fill:rgba(0, 0, 0, 0.12);
}

.entityListItemActions {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-self: baseline;
    margin: 10px 0 10px 0;
    color: #DADADA;
}

.entityListItemBtn {
    padding: 16px;
    border-radius: 2px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.12);
    background-color: #FFFFFF;
    color: $secondary_black !important;

    &:not(:last-child) {
        margin-right: 8px;
        
        &.rtl {
            margin-right: 0px;
            margin-left: 8px;
        }
    }
}

.entityListItemEditBtn:hover svg{
    fill: #21AE84;
}

.entityListItemDeleteBtn:hover svg{
    fill: #E26565;
}

.listPreloaderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.listPreloader svg {
    height: 100px;
    width: 100px;
    color: $primary_green;
}

.createButton {
    &:hover, &:focus {
        strong {
            color: var(--primary-green) !important;
        }
        path {
            fill: var(--primary-green);
        }
    }
}