@import "src/styles/numbers";

.systemMessageWrapper {
    width: auto;
    background: rgba(46, 49, 48, 0.96);
    opacity: 0.3;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    margin: 10px 10px 15px 10px;
    position: relative;
    box-sizing: border-box;
    padding: 9px 0 0 0;
}

.systemMessageText {
    color: #FFFFFF;
    margin: auto;
    font-family: SF UI Text,"Segoe UI";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.systemMessageTextInner {
    color: #FFFFFF !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.reverseText {
    direction: rtl;
}

.systemMessageDepartmentColor {
    height: 8px;
    width: 16px;
    border: 1px solid #FFFFFF;
    border-radius: 2px;
    margin: 0 3px;
}

.systemMessageAvatar {
    width: 18px;
    height: 18px;
    border: 1px solid #FFFFFF;
    border-radius: 50%;
    margin: 0 3px;
}

.systemMessageTime {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    bottom: 2px;
    margin-bottom: 2px;
    margin-inline: 3px;
    font-weight: bold;
    font-size: 11px;
    line-height: 8px;
    letter-spacing: -0.02em;
    color: rgba(255, 255, 255, 0.82);
}

@media (max-width: $mobile_width) {
    .systemMessageTime {
        line-height: 14px;
    }
}
