@import "src/styles/colorVars";
@import "src/styles/numbers";

.container {
  width: 100%;
  min-height: 45px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  max-width: 400px;
  margin-right: 0;
  color: $primary_black;
}

.content {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  min-height: 45px;
  cursor: pointer;
}

.icon {
  height: 30px;
  width: 30px;
  margin: 0 10px;

  svg {
    fill: $secondary_black;
    height: 28px;
    width: 28px;
  }
}

.incomingBackground {
  background-color: #e8e8e8;
}

.text {
  min-width: 130px;
  max-width: 300px;
  font-size: 14px;
  color: $primary_black;
  margin-bottom: 0;
}

.outgoingText {
  color: $primary_black;
}

.infectedDocumentContainer {
  width: 100%;
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 16px;
  border-top: 1px solid #e4e5eb;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background: $secondary_grey_border;
}

.infectedDocumentIcon svg {
  color: white;
  width: 12px;
  height: 12px;
}

.infectedDocumentText {
  font-family: SF UI Text;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #ffffff !important;
  padding: 0 5px;
}

.infectedDocumentTextNotScanned {
  color: #4c5250 !important;
}

.infectedDocumentTextPending {
  color: #546bd6 !important;
}

.infectedDocumentSvgPending {
  color: #546bd6 !important;
}

.infectedDocumentInfected {
  background: #db3b3b;
  border-top: 1px solid transparent;
}

.infectedDocumentSuspicious {
  background: yellow;
  border-top: 1px solid transparent;
}

.infectedDocumentClean {
  background: #0fb878;
  border-top: 1px solid transparent;
}

.infectedDocumentTooltip {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.infectedDocumentTooltipIcon {
  margin: 0 8px 0 0;
}

.infectedDocumentTooltipIcon svg {
  height: 20px;
  width: 20px;
}

.infectedDocumentTooltipText {
  color: white !important;
}

.documentMessageCaption {
  align-self: flex-start;
  line-height: 16px;
  margin-top: 0.5rem;
  max-width: 300px;
  color: $primary_white;

  &.incoming {
    color: $primary_black;
  }

  @media (max-width: $mobile_sm_width) {
    max-width: 200px;
  }
}