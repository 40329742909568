@import "src/styles/colorVars";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.spinner svg {
  height: 100px;
  width: 100px;
  color: var(--primary-green);
}

.infoMessage {
  visibility: hidden;
  opacity: 0;
  transform: translateY(10px);
  transition: opacity 0.5s ease, transform 0.5s ease;

  &.visible {
    visibility: visible;
    opacity: 1;
    color: $secondary_black;
    transform: translateY(0);
  }
}