@import "src/styles/colorVars";

.wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 80vh;
    overflow-y: auto;

    &.rtl {
        direction: rtl;
        text-align: right;

        [aria-label="search"] {
            transform: scaleX(-1);
        }
    }
}

.modalText {
    font-family: SF UI Text;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.02em;
    color: $primary_black !important;
    margin-bottom: 24px;
}

.loadingWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    [class*="anticon"] {
        font-size: 48px;
        width: 48px;
        height: 48px;
        color: $primary_green !important;
    }
}