.container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.descriptionContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.turnOffIcon{
    margin-top: 50px;
}
h3{
    font-family: SF UI Text,"Segoe UI";
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.02em;
    margin-top: 50px;
    margin-bottom: 32px;
}
.descriptionText{
    width: 80%;
    font-family: SF UI Text,"Segoe UI";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.02em;
    margin: 0;
}

.timer{
    font-family: SF UI Text,"Segoe UI";
    font-style: normal;
    font-weight: 300;
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    letter-spacing: -0.02em;
    margin-top: 40px;
    margin-bottom: 112px;
}
.buttonContainer{
    display: flex;
    margin: 2px 36px 32px 36px;
}
.buttonContainer > button{
    box-sizing: border-box;
    border-radius: 2px;
    width: 280px;
    padding: 12px 0;
    font-family: SF UI Text,"Segoe UI";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.02em;
}
.buttonContainer > button:nth-child(1){
    margin-right: 24px;
    background: #FFFFFF;
    border: 1px solid #D3D3D3;
    color: #EE6363;
}

.buttonContainer > button:nth-child(2){
    background: var(--primary-green);
    color: #fff;
    border: none;
}
