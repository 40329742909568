@import 'src/styles/colorVars';
@import 'src/styles/numbers';

.wrapper {
    display: grid;
    grid-template-rows: auto 1fr;
    border-left: 1px solid $primary_white;
    height: calc(100% - 7px);

    @media (max-width: $mobile_width) {
        height: 100%;
    }
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $header_height;
    min-height: $header_height;
    background-color: $primary_white;
    position: relative;
    min-width: 0px;
    padding: 0px 12px;
    z-index: 20;
}

.body {
    display: flex;
    flex-direction: column;
    padding: 0px;
    overflow-y: auto;

    [class='ant-tabs-nav'] {
        margin: 0 !important;
        position: sticky !important;
        top: 0;
        z-index: 10;

        &::before {
            opacity: 0.2;
        }
    }
    [class*='ant-tabs-tab'] {
        font-weight: bold;
        justify-content: center;
    }
    [class='ant-tabs-nav-list'] {
        display: grid !important;
        grid-template-columns: 1fr 1fr;
        width: 319px;
    }
    [class*='ant-tabs-content'] {
        height: 100%;
    }
}

.icon {
    height: 20px;
    width: 20px;
    fill: $primary_black;
    cursor: pointer;

    &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
}

.tabs {
    [class="ant-tabs-nav"] {
        background-color: $primary_white;
    }
    [class='ant-tabs-tab-btn'] {
        color: $secondary_black;
        opacity: 0.8;
    }
    [class*='ant-tabs-tab-active'] {
        [class='ant-tabs-tab-btn'] {
            color: $primary_black !important;
            opacity: 1;
        }
    }
    [class*='ant-tabs-ink-bar'] {
        background: var(--primary-color);
    }
}

.dateHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 16px;
    color: $secondary_black;
    background-color: $primary_white;
    font-weight: normal;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    min-height: 46px;
    position: sticky;
    top: 46px;
    background-color: white;
    font-size: 12px;
    z-index: 10;
    border-bottom: 1px solid $primary_grey_border;
}

.mediaWrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 4px;
    padding: 8px 12px;
    margin-bottom: 8px;
}

.docWrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: 4px;
    padding: 8px 16px;
}

.mediaList {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.mediaBox {
    position: relative;
    width: 90px;
    height: 90px;

    img,
    video {
        width: 100%;
        height: 100%;
        min-height: 100%;
        object-fit: cover;
        cursor: pointer;

        &:hover {
            filter: brightness(85%);
        }
    }

    &:hover {
        label {
            display: inline-flex !important;
        }
    }

    &.legacy {
        img,
        video {
            cursor: not-allowed;
            &:hover {
                filter: none;
            }
        }
    }

    /** Always show checkbox in mobile since it's not obvious how to select without hover interaction */
    @media (max-width: $mobile_width) {
        label {
            display: inline-flex !important;
        }
    }
}

.docBox {
    position: relative;
    cursor: pointer;
    background-color: $tertiary_grey;

    &:hover {
        filter: brightness(85%);
        label {
            display: inline-flex !important;
        }
    }

    @media (max-width: $mobile_width) {
        label {
            display: inline-flex !important;
        }
    }
}

.videoWrapper {
    position: relative;
    width: 100%;
    height: 100%;
    background: transparent;
    opacity: 1;

    & > div {
        margin-top: 0 !important;
    }
}

.videoLoader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.downloadLoader {
    margin: 0 10px;
}

.mediaBoxCheckbox {
    position: absolute;
    top: 6px;
    right: 6px;

    &:not(.checked) {
        display: none;
    }

    [class*='ant-checkbox'] {
        top: 0 !important;
    }
}

.checkbox {
    [class="ant-checkbox"] {
        border-radius: 2px;
        border: 1px solid $primary_black;
    }
}

.loading-icon {
    svg {
        height: 32px;
        width: 32px;
    }
}

.checkedBox {
    outline-color: $primary_green !important;
}

.image,
.videoWrapper,
.docContentWrapper {
    outline: 3px solid transparent;
    margin: 0;

    img, video {
        border-radius: 0;
    }
}

.docContentWrapper {
    border-color: $tertiary_grey;
    padding: 4px 0;
    display: flex;
    align-items: center;
}

.docBoxMessage {
    & > div {
        background-color: transparent;
    }
}

.tooltipContainer {
    padding: 8px;
}

.tooltipInfo {
    font-size: 12px;
    color: $primary_grey;
    margin-bottom: 8px;
}

.tooltipAction {
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    color: $primary_green;
    cursor: pointer;
}

.iconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
        background-color: $tertiary_grey;
    }
}

.headerContentWrapper {
    display: flex;
    align-items: center;
    gap: 4px;
}

.selectedFilesLabel {
    font-weight: bold;
}