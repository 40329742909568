@import "src/styles/numbers";
@import "src/styles/colorVars";

.image-message-container {
    margin-top: 5px;

    &.small {
        [class='ant-image'] {
            height: 90px;
            width: 90px;
            max-height: 90px;
        }
        .image-message {
            max-height: 90px;
        }
        .image-message-filename {
            max-width: 90px;
        }
        .image-message-image {
            max-width: 90px;
            max-height: 90px;
        }
        .message-image-placeholder {
            width: 90px;
            height: 90px;
            max-height: 90px;
        }
    }
    &.medium {
        [class='ant-image'] {
            height: 200px;
            width: 200px;
            max-height: 200px;
        }
        .image-message {
            max-height: 200px;
        }
        .image-message-filename {
            max-width: 200px;
        }
        .image-message-image {
            max-width: 200px;
            max-height: 200px;
        }
        .message-image-placeholder {
            width: 200px;
            height: 200px;
            max-height: 200px;
        }
    }
    &.large {
        [class='ant-image'] {
            height: 300px;
            width: 300px;
            max-height: 300px;

            @media (max-width: $mobile_sm_width) {
                height: 200px;
                max-height: 200px;
                width: 200px;
            }
        }
        .image-message {
            max-height: 300px;

            @media (max-width: $mobile_sm_width) {
                max-height: 200px;
            }
        }
        .image-message-filename {
            max-width: 300px;

            @media (max-width: $mobile_sm_width) {
                max-width: 200px;
            }
        }
        .image-message-image {
            max-width: 300px;
            max-height: 300px;

            @media (max-width: $mobile_sm_width) {
                max-width: 200px;
                max-height: 200px;
            }
        }
        .message-image-placeholder {
            width: 300px;
            height: 300px;
            max-height: 300px;

            @media (max-width: $mobile_sm_width) {
                width: 200px;
                height: 200px;
                max-height: 200px;
            }
        }
    }
    
    [class='ant-image'] {
        display: flex;
        flex-direction: column;
    }
}

.image-message {
    display: block;
    height: auto;
    width: auto;
    margin-top: 5px;
    margin-bottom: 5px;
    cursor: pointer;
}

.image-message-filename {
    letter-spacing: 0;
    position: relative;
    font-weight: 400;
    font-size: 14px;
    word-wrap: break-word;
    margin-top: 1em;
    margin-bottom: 0;
    color: $primary_black;
}

.image-white-text {
    color: #ffffff;
}

.ant-image-preview-img {
    border: 4px solid white;
    border-radius: 3px;
}

.ant-btn.imageDownloadButton {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 1090 !important;
}

.downloadImageButtonWrapper {
    cursor: pointer;
    padding: 12px;
    margin-left: 12px;
    display: flex;
    align-items: center;
    position: relative;
    bottom: 1px;
}

.downloadImageButton {
    max-width: 18px;
    max-height: 18px;
    -webkit-filter: invert(100%);
    filter: invert(100%);
}

[class^=ant-], [class*= ant-], [class^=ant-] *, [class*= ant-] *, [class^=ant-] *::before, [class*= ant-] *::before, [class^=ant-] *::after, [class*= ant-] *::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.image-message-image {
    background-color: black;
    object-fit: cover;
    height: 100% !important;
    border-radius: 4px;
}

.image-message-container .ant-image-error, div.ant-image-placeholder {
    min-width: 100px;
    position: static;
}

.ant-image-placeholder > img {
    height: 80px;
    width: 80px;
    color: #D5D6DD;
}

.ant-image-preview-img {
    background-color: black;
}

.image-message-container .ant-image-error img {
    width: 80px;
    margin: 30px auto;
}

.message-image-placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;

    svg {
        border-radius: 4px;
      }
}

/* show image preview commands clear and push the image bellow it */
[class="ant-image-preview-operations"] {
    background-color: rgba(0, 0, 0, 0.7) !important;
}
[class="ant-image-preview-img-wrapper"] {
    top: calc(46px) !important;
}
[class="ant-image-preview-img"] {
    max-height: calc(100% - 48px) !important;
}

@media (max-width: $mobile_sm_width) {
    li.ant-image-preview-operations-progress {
        left: 36px;
    }
}
