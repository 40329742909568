.layout{
  width: 100%;
  height: 100%;
}
.layout.active{
  position: relative;
  z-index: 101;
}

.background{
  z-index: 100;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
}
.background.active{
  display: block;
  background-color: rgba(0, 0, 0, 0.22);
}