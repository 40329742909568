@import 'src/styles/colorVars';

.specialContainer {
    display: flex;
    flex-direction: column;
    color: $primary_white;

    &.incoming {
        color: $primary_black;
    }
}

.unsupportedMessageContainer {
    display: flex;
    flex-direction: column;
}

.unsupportedMessageType {
    color: $secondary_black;
    font-size: 12px;
}