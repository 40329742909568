.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100% - 162px);
  width: 80%;
  margin: auto;
}

.logo {
  border: 1px solid #E9E9E9;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  font-family: SF UI Text;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #383C3A;
  margin: 16px 0 8px
}

.text {
  font-family: SF UI Text;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #383C3A;
  opacity: 0.7;
}

.button {
  width: 138px;
  height: 40px !important;
  margin-top: 24px;
}
