@import "src/styles/numbers";

.bulkMessageWrapper {
    display: grid;
    grid-template-rows: auto 1fr auto;
    max-height: 80vh;
    min-height: 80vh;
    height: 100%;

    &.rtl {
        direction: rtl;
    }

    @media (min-width: $desktop_width) {
        min-height: 70vh;
    }
}
