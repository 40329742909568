@import "src/styles/colorVars.scss";

.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 24px;
  min-height: 66px;
  max-height: 66px;
  background-color: $primary_white;
}

.warningIcon {
  margin-right: 12px;
}

.errorText {
  color: $primary_red;
}

.unblockButton {
  height: 40px;
}

.buttonContent {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.preloader svg{
  color: $primary_green;
}

.buttonText {
  text-transform: capitalize;
}

.fillButton {
  color: $primary_green !important;
}

.fillButton svg path{
  fill:$primary_green
}
