.icon {
  margin-left: 10px
}

.icon svg {
  width:20px;
  height: 20px;
}

.icon:hover {
  cursor: pointer;
}
