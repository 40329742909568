@import 'src/styles/colorVars';
@import 'src/styles/numbers';

.filterContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;
    position: absolute;
    top: 0;
    right: 0;
    background: $primary_white;
    z-index: 10;

    &.rtl {
        right: unset;
        left: 0;
    }
}

.filtersWrapper {
    display: grid;
    grid-template-columns: 1fr auto auto;
    align-items: center;
    border-bottom: 1px solid $primary_grey_border;
    height: 40px;

    @media (max-width: $mobile_sm_width) {
        column-gap: 8px;
    }
}

.search {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    gap: 12px;
    position: relative;

    @media (max-width: $mobile_sm_width) {
        grid-column: 1 / 4;
    }
}

.searchInput {
    background-color: #f5f5f580;
    padding-right: 30px;

    &::placeholder,
    &:focus::placeholder {
        text-align: left;
    }
}

.searchIcon svg {
    height: 24px;
    width: 24px;
    fill: $primary_grey;
}

.cleanSearchInput {
    height: 20px;
    width: 20px;
    cursor: pointer;

    &.rtl {
        left: 12px;
        right: unset;
    }
}

@media (max-width: $mobile_sm_width) {
    .filtersWrapper {
        align-items: flex-end;
    }
    .filterContainer {
      max-width: 180px;
    }
    .search {
      gap: 0;
    }
}
