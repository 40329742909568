.audio-container {
    width: 100%;
    position: relative;
    margin-top: 4px;
}

.audio-controller {
    max-width: 100%;
}

.audio-controller:focus {
    outline: none;
}
