.someErrorContainer {
    position: fixed;
    top: 15px;
    margin: 0 auto; /* Will not center vertically and won't work in IE6/7. */
    left: 0;
    right: 0;
    height: 64px;
    width: 250px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:space-evenly;
    vertical-align: center;

    background: rgba(218, 63, 63, 0.96);
    box-shadow: 8px 8px 32px rgba(0, 0, 0, 0.08);
    border-radius: 2px;
    z-index: 2;
}

.someErrorImage {
    cursor: pointer;
}

.someErrorText {
    margin: 0;
    font-family: SF UI Text,"Segoe UI";
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #FFFFFF;
}
