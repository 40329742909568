@import "src/styles/numbers";

.clientInfo {
  margin: 0 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: space-between;

  height: 168px;

  @media (max-width: $mobile_width) {
    margin: 0 12px;
  }
}

.clientInfoSubheader {
  font-family: SF UI Text;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;

  letter-spacing: -0.02em;

  color: #4C5250;

  opacity: 0.7;
}

.clientInfoDetails {
  font-family: SF UI Text;
  font-size: 14px;
  line-height: 24px;

  letter-spacing: -0.02em;

  color: #383C3A;
}
