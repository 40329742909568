.simpleModalContainer {
    display: flex;
    flex-direction: column;
    height: 80vh;
}

.simpleModalHeader {
    height: 80px;
    min-height: 80px;
    width: 100%;
    border-bottom: 2px solid #DEDEDE;

    letter-spacing: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    word-wrap: break-word;
    position: relative;
    margin-top: 0;
    margin-bottom: 0;
    color: #353535;
    padding: 0 32px;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.simpleModalTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.simpleModalTitleIcon {
    height: 16px;
    width: 16px;
    margin-right: 16px;
}

.simpleModalGreenUnderline svg{
    fill: #30C498;
}

.simpleModalGreenUnderline::after {
    content: '';
    border-bottom: 2px solid #30C498;
    width: 56px;
    left: 32px;
    position: absolute;
    display: block;
    top: 78px;
}

.simpleModalRedUnderline svg{
    fill: #E26565;
}

.simpleModalRedUnderline::after {
    content: '';
    border-bottom: 2px solid #E26565;
    width: 56px;
    left: 32px;
    position: absolute;
    display: block;
    top: 78px;
}

.closeIconWrapper {
    width: 32px;
    height: 32px;
    border-radius: 2px;
    text-align: center;
    line-height: 32px;
    transition: 500ms;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.closeIconWrapper:hover {
    background: #F5F5F5;
}

.content {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
}

.centerContent {
    align-items: center;
}

.simpleModalBottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 104px;
  z-index: 1;
  box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.12);
  border-radius: 2px 2px 0 0;
  padding: 28px 40px;
}

.simpleModalBottomButtons {
    min-width: 70%;
    display: flex;
    justify-content: space-between;
}

.cancelButtonStyles {
    margin-right: 20px;
}

.buttonsWrap {
    display: flex;
    align-items: center;
    border-top: 2px solid #eaeaea;
    padding: 20px;
    justify-content: flex-end;
    background: #fff;
    margin-top: auto;
}
