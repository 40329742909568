@import "src/styles/colorVars.scss";
@import "src/styles/numbers";

.quickRepliesField {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #EAEAEA;
  justify-content: space-between;
}

.managerReply {
  cursor: pointer;
  border-left: transparent 2px solid;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 100px;

  &:hover {
    border-left: #21AE84 2px solid;
    background-color: #F8F8F8;

    span {
      color: #636766;
    }
  }
}

.quickRepliesFieldActive {
  background: $primary_grey;
}

.replyNameSpaceLeft, .deleteReplyText {
  width: 532px;
  overflow-wrap: break-word;
  margin-left: 40px;
  height: 100%;
  display: flex;
  align-items: center;
}

.replyNameSpaceLeft {
  flex-direction: column;
  padding-bottom: 31px;
}

.replyNameSpaceRight {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding-right: 40px;
  align-self: baseline;
  margin-top: 20px;
  color: #DADADA;
}

.replyText {
  font-size: 13px;
  color: #747775;
  padding: 32px 0 0 0;
  display: block;
  width: 100%;
  text-align: justify;
}

.replyNameButtonEdit, .replyNameButtonDelete {
  padding: 16px 16px 17.33px 17.33px;
  border-radius: 2px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.12);
  background-color: #FFFFFF;
}

.replyNameButtonEdit {
  margin-right: 8px;

  &:hover svg {
    fill: #21AE84;
  }
}

.replyParent {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 16px;

  &:hover {
    .replyNameButtonEdit svg {
      fill: $primary_green;
    }
  }
}

.replyNameButtonDelete:hover svg{
  fill: #E26565;
}

.replyDepartmentsWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 12px;
  width: 100%;
  overflow: auto;
}

@media (max-width: $mobile_width) {
  .quickReplies {
    bottom: 50px;
  }
  .quickRepliesField {
    width: 100vw;
  }
  .quickRepliesMainField {
    height: 50px;
  }
}