@import 'src/styles/colorVars';
@import 'src/styles/numbers';

.container {
    background-color: $tertiary_green_bg;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    display: grid;
    grid-template-rows: 1fr;
    height: 100%;
    width: 100%;
}

.box {
    display: grid;
    grid-template-rows: 1fr auto;
    overflow: hidden;
    width: 100%;

    @media (max-width: $mobile_width) {
        width: 100vw;
    }
}

.mapContainer {
    display: flex;
    align-items: center;
    justify-self: center;
    justify-content: center;
    width: 100%;
}

.lowerBlock {
    height: $compose_height;
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    padding: 8px 12px;
    gap: 16px;
    background: rgba(255, 255, 255, 0.6);
    border-top: 1px solid #eeeef1;
    outline: none;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.subtitle {
  font-size: 12px;
  color: $primary_black;
  opacity: 0.7;
}

.sendButton {
    width: 32px;
    height: 32px;
    line-height: 32px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    transition: background 0.15s ease;
    color: $primary_white;
    background: var(--background-color);

    &:hover {
        background: var(--background-color-hover) !important;
    }
}

.cancelButton {
    font-size: 13px;
    letter-spacing: -0.02em;
}
