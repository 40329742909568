@import "src/styles/colorVars";
@import "src/styles/numbers";

.topBarButton {
  width: 20%;
  background: $primary_grey_bg;
  font-family: SF UI Text,"Segoe UI";
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 160%;
  letter-spacing: -0.02em;
  cursor: pointer;
  color: #565656;
  border-right: 1px solid $secondary_grey_border;

  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 0 24px;
  border-bottom: 2px solid transparent;
}

.topBarButtonTitle {
  font-size: 12px;
  line-height: 133%;
  text-transform: uppercase;

}

.topBarButtonTitle.bold {
  font-weight: bold;
}

.topBarButtonChats {
  color: $tertiary_black;
  font-weight: 600;
  white-space: nowrap;
}

.topBarButtonChats.active {
  color: $primary_green;
}

.topBarButton.active,
.topBarButton:hover {
  background: #FFFFFF;
  border-bottom: 2px solid $primary_green;
  color: $primary_green;
}

@media (max-width: $mobile_width) {
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16px;
    width: 16px;
  }
  .topBarButton {
    padding: 8px;
    min-width: 20%;
    height: 64px;
  }
  .topBarButtonTitle {
    line-height: normal;
  }
}
