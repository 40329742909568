@import "src/styles/numbers";
@import "src/styles/colorVars";

.logOutSection {
  width: 100%;
  height: 72px;
  min-height: 72px;
  border-bottom: 1px solid #F0F0F0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.logOutSectionUser {
  display: flex;
  flex-direction: column;
  font-family: SF UI Text,"Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 157%;
  letter-spacing: -0.33px;
  color: #383C3A;
  margin: 0 12px;
}

.logOutSectionStatus {
  font-weight: normal;
  font-size: 12px;
  line-height: 133%;
  letter-spacing: -0.15px;
  color: #888A89;
  text-align: right;
}

.logOutSectionLogout svg{
  fill: #EB584E;
}

.logoutButtonCircle {
    padding: 0;
    height: 31px;
    width: 31px;
    border-radius: 50%;
    border: 1px solid #EAEAEA;

    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 24px 0 16px;
}

.logoutButtonCircle:hover {
  background-color: #EB584E !important;
  cursor: pointer;
  border: 1px solid #EAEAEA !important;
}

.logoutButtonCircle:hover .logOutSectionLogout svg{
  fill: white;
}

.useTemplateButton {
  max-width: 100px;
  min-width: 100px;
  padding: 2px !important;
}

@media (max-width: $mobile_width) {
  .logOutSection {
    display: none;
  }
}
