.wizard-bottom-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    height: 104px;
    z-index: 1;
    box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.12);
    border-radius: 2px 2px 0px 0px;

    &.rtl {
        .ant-steps-item-title::after {
            left: auto !important;
            right: 0 !important;
        }
    }
}

.ant-btn-primary.add-agent-save-button {
    border-color: transparent;
}

.ant-steps.wizardSteps {
    width: 200px;
}

.ant-steps-item {
    padding-left: 0 !important;
}

.ant-steps-item-icon {
    margin-right: 0 !important;
}

.ant-steps-item-title::after {
    left: 0 !important;

    &.rtl {
        left: auto !important;
        right: 0 !important;
    }
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background-color: var(--primary-green) !important;
    border-color: var(--primary-green);
}

.ant-steps-item-wait:hover .ant-steps-item-icon {
    border-color: var(--primary-green) !important;
}

.ant-steps-item-wait:hover .ant-steps-icon {
    color: var(--primary-green) !important;
}

.ant-steps-item-wait .ant-steps-item-icon:hover {
    border-color: var(--primary-green) !important;
}

.ant-steps-item-finish .ant-steps-item-title::after {
    background-color: var(--primary-green) !important;
}

.ant-steps-item-finish .ant-steps-item-icon {
    border-color: var(--primary-green) !important;
    background-color: var(--primary-green) !important;
}

.ant-steps-item-finish .ant-steps-icon {
    color: #ffffff !important;
}

.ant-btn.wizard-cancel-button, .ant-btn.wizard-next-button {
    min-height: 48px;
    height: 48px;
    min-width: 168px;
    border: 1px solid #EAEAEA;
}

.wizard-cancel-button:hover .wizard-cancel-button-text {
    color: var(--primary-green);
}

.ant-typography.wizard-next-button-text {
    font-family: SF UI Text;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
}
