.modalText {
  font-family: SF UI Text;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #383C3A !important;
  margin-bottom: 24px;
}