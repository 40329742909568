@import 'src/styles/colorVars';

.sentContainer {
    display: flex;
    align-items: center;
    gap: 4px;
    width: fit-content;

    svg {
        width: 16px;
        height: 16px;
        fill: $primary_black;
    }
}

.tooltipBodyContainer {
    [class='ant-tooltip-inner'] {
        width: 200px;
        overflow-y: auto;
        text-align: center;
    }
}
