.loaderWrap {
    position: relative;
    height: 45px;
    min-height: 45px;
    width: 100%;
}

.loader {
    color: gray !important;
    display: block !important;
    position: absolute !important;
    top: 10px;
    right: 0;
    bottom: 0;
    left: 0;
    :global {
        .ant-spin-text {
            display: inline-block !important;
            margin-left: 1em;
            vertical-align: text-bottom;
        }
    }
}
