@import "src/styles/colorVars";

.userDepartment {
    display: flex;
    overflow: auto;
    margin-right: 12px;
}

.teaserContainer {
    display: flex;
    align-items: center;
    gap: 4px;
}

.agentDetails {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
}

.agentColor {
    height: 30px;
    width: 30px;
    min-width: 30px;
    background-color: $secondary_grey;
}

.disabled {
    color:rgba(0, 0, 0, 0.12);
}