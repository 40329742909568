@import "src/styles/colorVars";

.deleteTemplateContent {
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.ant-btn.templateCancelButton, .ant-btn.templateDeleteButton{
    height: 48px;
    width: 168px;
    border: 1px solid #EAEAEA;
    z-index: 1;
}

.templateDeleteButton .ant-typography {
    color: white;
}

.deleteTemplateHolder {
    width: 656px;
    background-color: #F5F5F5;
    margin-top: 32px;

    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 87px;
}

.deleteTemplateText {
    white-space: pre-wrap;
    width: 100%;
    align-self: flex-start;
    padding: 20px 40px;
    overflow-wrap: break-word;
    height: 100%;
    display: flex;
    align-items: center;
}

.deleteTemplateTextRtl {
    direction: rtl;
    text-align: right;
}

.addReplyWrapper {
    background-color: white;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.addReplyBottom {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 24px;
    border-top: 2px solid #EAEAEA;
    height: 86px;
    padding: 40px 20px;
}

.button {
    width: 168px;
    height: 48px !important;
    flex-basis: auto;
    flex-grow: unset;
}