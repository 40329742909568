@import "../../../../styles/colorVars.scss";

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 24px;
}

.contactWrapper {
  height: 80px;
  min-height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $primary_grey;

  img {
    margin-right: 8px;
  }

  &.rtl {
    flex-direction: row-reverse;
  }
}

.tutorialsWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  width: 100%;
  padding: 12px 0;
  margin-bottom: 12px;
  border-bottom: 1px solid $primary_grey;

  &.rtl {
    align-items: flex-end;
  }
}

.tutorialsTitle {
  font-size: 16px;
  font-weight: 600;
  color: $primary_black;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 4px;

  svg {
    width: 32px;
    height: 32px;
  }

  &.rtl {
    flex-direction: row-reverse;
  }
}

.tutorialsLink {
  color: $primary_black;
  text-decoration: underline;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;

  svg {
    width: 24px;
    height: 24px;
  }

  &.rtl {
    flex-direction: row-reverse;
  }
}

.appsWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 100%;
  margin-bottom: 12px;

  &.rtl {
    align-items: flex-end;
  }
}

.notificationWrapper {
  padding: 12px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  border-bottom: 1px solid $primary_grey;

  &.rtl {
    direction: rtl;
  }

  svg {
    width: 24px;
    height: 24px;
    fill: $primary_green;
  }
}
