@font-face {
  font-family: SF UI Text;
  src: url(./assets/fonts/SFUIText.ttf);
}

@font-face {
  font-family: SF Pro Text;
  src: url(./assets/fonts/SFPROText.ttf);
}

@font-face {
  font-family: SF UI Display;
  src: url(./assets/fonts/SFUIDisplay.ttf);
}

body {
  /*box-sizing: border-box;*/
  margin: 0;
  padding: 0;
  width: 100%;
}

/*Remove after cleaning material*/
/**{*/
/*  box-sizing: content-box;*/
/*}*/

/*[class^=ant-],*/
/*[class*= ant-],*/
/*[class^=ant-] *,*/
/*[class*= ant-] *,*/
/*[class^=ant-] *::before,*/
/*[class*= ant-] *::before,*/
/*[class^=ant-] *::after,*/
/*[class*= ant-] *::after {*/
/*  -webkit-box-sizing: initial;*/
/*  box-sizing: initial;*/
/*}*/
