@import "src/styles/colorVars";

.modalContent {
  padding: 32px 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  &.rtl {
    direction: rtl;
    text-align: right;
  }
}

.inputWrapper {
  width: 46%;
  display: flex;
  flex-direction: column;
}

.previewWrapper {
  width: 46%;
  height: 50%;
}

.input {
  margin-bottom: 12px !important;
  
  input {
    height: 100% !important;
  }
}

.uploadButtonContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
}

.uploadButton {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-width: 0;
  gap: 12px;

  & > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  [class*="anticon"] {
    min-width: 16px;
  
    svg {
      width: 16px;
      height: 16px;
      fill: $primary_white;
    }
  }

}

.buttonHelpText {
  display: flex;
  align-items: center;
  gap: 8px;
}

.infoText {
  font-family: SF UI Text;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: -0.02em;
  margin: 0;

  color: $tertiary_black;
}

.infoCircle {
  svg {
    fill: $tertiary_black;
  }
}