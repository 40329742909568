@import "src/styles/numbers";

.deleteConfirmation::after {
    border-bottom: 2px solid #EE6363;
}

.title {
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.titleIcon {
    display: flex !important;
    align-items: center;
}

.titleText {
    font-size: 20px;
}

.closeModal {
    display: flex;
    align-items: center;
}

.wrapper {
    padding: 20px 25px;
    border-bottom: 2px solid #EAEAEA;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 84px;
    min-height: 84px;

    &::after {
        content: '';
        border-bottom: 2px solid #30C498;
        width: 56px;
        left: 25px;
        position: absolute;
        display: block;
        top: 82px;
    }
}

.channelSelectorWrapper {
    display: flex;
    align-items: center;
    gap: 25px;
}

.channelSelector {
    [class="ant-select-selector"] {
        width: 300px !important;
    }

    @media (max-width: $mobile_sm_width) {
        [class="ant-select-selector"] {
            width: 150px !important;
        }
    }
}

@media (max-width: $mobile_sm_width) {
    .templatesTitleText {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .wrapper {
        padding: 20px 15px;

        &::after {
            left: 15px;
        }
    }

    .titleText {
        font-size: 18px;
        line-height: 18px;
    }
}
