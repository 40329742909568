@import 'src/styles/colorVars';
@import 'src/styles/numbers';

@keyframes pulse {
    0% {
        opacity: 0.5;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.5;
    }
}

.container {
    width: 100%;
    height: 66px;
    background-color: rgba(255, 255, 255, 0.6);
    padding: 12px 14px;
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: flex-end;

    @media (max-width: $mobile_width) {
        gap: 8px;
    }

    .sendButton {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border: none;
        cursor: pointer;
        color: $primary_white !important;
        background: var(--background-color);

        svg {
            width: 14px;
            height: 14px;
        }

        &[disabled] {
            cursor: not-allowed;
            background-color: #dcdcdc !important;

            svg {
                fill: white;
            }

            path {
                stroke: white;
            }
        }
    }

    .deleteButton {
        display: none;
        align-items: center;
        justify-content: center;

        svg {
            width: 18px;
            height: 18px;
            font-size: 18px;

            path {
                fill: $primary_white;
            }
        }

        &.show {
            display: flex;
        }
    }

    .recordButton {
        width: 32px;
        height: 32px;
        line-height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border: none;
        transition: background 0.15s ease;
        background-color: $primary_red;
        color: $primary_white;

        svg {
            font-size: 18px;
        }

        &:hover {
            color: $primary_white !important;
            background-color: $primary_red;
        }
    }
}

.recordingWrapper {
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    
    .recording {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 16px;
        background-color: $primary_white;    
        padding: 0 12px;
        width: 100%;
        height: 100%;
        animation: pulse 2s infinite;
    }

    audio {
        height: 100%;
        width: 100%;
    }
}