@import "src/styles/colorVars";

.divider {
    width: 100%;
    height: 1px;
    background: #ddd;
}

.preloader {
    font-size: 48px;
    width: 48px;
    height: 48px;
    margin: 16px 0;
    color: $primary_green !important;
}

.empty {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 100px;
    height: 100%;
}

.listContainer {
    display: flex;
    flex-direction: column;
}
