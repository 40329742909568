@import 'src/styles/colorVars';
@import 'src/styles/numbers';

.myselect-wrapper {
    width: 100%;

    [class='ant-select-selector'] {
        height: 40px !important;
        padding: 0 12px !important;
    }

    &.ant-select-focused {
        [class='ant-select-selector'] {
            border-color: $primary_green !important;
            box-shadow: 0 0 0 2px rgba(2, 202, 121, 0.2) !important;
        }
    }

    [class='ant-select-selection-placeholder'] {
        display: flex;
        align-items: center;
    }

    input {
        height: 40px;

        &[type="search"] {
            height: 100% !important;
        }
    }

    [class='ant-select-arrow'] {
        right: 12px;
    }

    [class='ant-select-selection-item'] {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    &:hover {
        [class='ant-select-arrow'] svg {
            fill: $primary_green;
        }
    }

    &.small {
        [class='ant-select-selector'] {
            height: 32px !important;
        }

        input {
            height: 32px;
        }
    }
    &.large {
        [class='ant-select-selector'] {
            height: 48px !important;
        }

        input {
            height: 48px;
        }
    }

    &.rtl {
        [class='ant-select-selection-item'] {
            direction: rtl;
            padding-left: 18px !important;
            padding-right: 0 !important;
            justify-content: right !important;

            &::after {
                content: none !important;
            }
        }
        [class='ant-select-arrow'] {
            right: auto;
            left: 12px;
        }
    }

    &.fullWidth {
        width: 100%;
        max-width: 100%;
    }
}

.option {
    &.rtl {
        direction: rtl;
    }

    & > div {
        display: flex;
        align-items: center;
        gap: 8px;
    }
}

.myselect-popup {
    &.rtl {
        direction: rtl;
    }

    [class*='ant-select-item ant-select-item-option'] {
        & > div {
            display: flex;
            align-items: center;
            gap: 8px;
        }
    }
}
