.filterAgentsSelect {
    min-width: 49%;
    max-width: 49%;
    width: auto;

    [class='ant-select-selection-item'] {
        img {
            display: none;
        }
    }
}

.filterAgentsOptionText {
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 2px;
}

.filterAgentColor {
    min-width: 16px;
    height: 8px;
    display: block;
    float: left;
}

.filterAgentsOption {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
}

.filterAgentsContent {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 12px;
}
