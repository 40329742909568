.wizard-body-main {
    overflow: hidden;
    height: 100%;
    position: relative;

    display: grid;
    gap: 1rem;

    &--with-preview {
        grid-template-columns: 1fr auto;
        padding: 1rem;
    }
}
