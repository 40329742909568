@import "src/styles/colorVars.scss";

.richBlock {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.inputsBlock {
    display: flex;
    flex-direction: column;
    width: 48%;
}

.templateAddHeader {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.shortSelect {
    width: 30%;
    min-width: 30%;
    text-overflow: ellipsis;
    text-transform: capitalize;
}

.templateParamsInput {
    margin-bottom: 10px !important;
    width: 24%;
}

.templateParamsInlineInput {
    margin-left: 4px !important;
}

.templateAddFooter {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.templateAddButtons {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.addButton {
    width: 100%;
    margin-top: 8px;
}

.actionButtonBlock {
    margin-top: 8px;
}

.templateAddButtonBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2px;
}

.textInputBlock {
    width: 100%
}

.inputWithDeleteBlock {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid $primary_grey;
    border-radius: 2px;
    transition: all 0.3s;
}

.inputWithDeleteBlock:hover {
    border-color: $primary_green_hover;
}

.inputWithDelete {
    border: none !important;
}

.inputDisabled {
    background-color: #F5F5F5;
}

.deleteIcon {
    margin-right: 8px;
}

.buttonDataBlock {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.buttonsError {
    color: $primary_red !important;
}

.selectText {
    text-transform: capitalize;
}

.previewWrapper {
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.iconFill svg path{
    fill: $primary_green !important;
}

.previewTextFill {
    color: $primary_green !important;
}

.previewButtonContent {
    display: flex;
    align-items: center;
    gap: 12px;

    &.rtl {
        svg {
            transform: scaleX(-1);
        }
    }
}

.sampleDataErrorContainer {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 8px 0;
    font-size: 13px;
    color: $primary_red;
}

.errorCircle {
    svg {
        fill: $primary_red;
    };
}