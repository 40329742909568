@import 'src/styles/numbers';
@import 'src/styles/colorVars';

.conversationSearch {
    height: 72px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-start;

    @media (max-width: $mobile_width) {
        width: 100vw;
        height: 60px;
        border-bottom: 1px solid $antd_border;
        justify-content: space-between;
        
        .searchWrapper,
        .contentWrapper {
          padding: 8px 16px;
        }

        &.showSearchBar {
          flex-direction: column;
          height: 116px;
      }
    }
}

.searchWrapper {
    display: flex;
    align-items: center;
    height: 56px;
    width: 100%;
    
    @media (max-width: $mobile_width) {
      border-top: 1px solid $antd_border;
      padding: 0 16px;
    }
}

.conversationSearchFulltext {
  label {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    line-height: 12px;

    [class="ant-checkbox"] {
      top: 0;
    }
  }
}

.filterIcon {
  position: relative;
}

.filtersApplied {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: $primary_red;
}

.searchInput {
  gap: 8px;
}

.cleanSearchInput {
    height: 20px;
    width: 20px;
    cursor: pointer;

    &.rtl {
        right: auto;
        left: 47px;
    }
}

.chatSearchIcon svg {
    height: 20px;
    width: 20px;
    fill: grey;
}

.hidden {
    display: none !important;
}

.icon {
    width: 40px;
    height: 40px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    fill: $secondary_black;

    svg {
        width: 24px;
        height: 24px;
    }

    &:hover {
        cursor: pointer;
        background-color: rgba(196, 196, 196, 0.3);
    }

    &.active {
        background-color: rgba(196, 196, 196, 0.3);
    }
}

.contentWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    padding-right: 8px;
    
    @media (max-width: $mobile_width) {
      padding-right: 0;
      padding: 0 16px;
      width: 100%;
      display: grid;
      grid-template-columns: 1fr auto;
    }
}

.actionsWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
