@import "src/styles/colorVars.scss";

.generalBlock {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
}

.label {
    display: flex;
    margin: 20px 0 5px;
    justify-content: space-between;

    font-family: SF UI Text;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: rgba(56, 60, 58, 0.7);
}

.label:first-child {
    text-transform: capitalize;
}

.labelIcon {
    display: flex !important;
    align-items: center;
    cursor: pointer;
}

.advancedSettingsBlock {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 50px;
    position: relative;

    &.noMargin {
        margin-bottom: 0;
    }
}

.labelTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #383C3A;
}

.advancedButton {
    height: 48px !important;
    transition: none !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    gap: 8px;

    & > span {
        margin-left: 0 !important;
        margin-right: 0;
    }

    .quickReplyIcon {
        position: relative;
        top: 1px;
    }

    &:hover {
        border-color: $primary_green !important;

        .quickReplyIcon path {
            fill: $primary_green;
        }

        .createNewTemplateButtonText {
            color: $primary_green !important;
        }
    }
}

.advancedChosenButton {
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.advancedSettingsTextblock {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    display: flex;
    flex-direction: row;
}

.advancedSettingsTextblockRequired {
    border-color: #EE6363;
}

.advancedTextboxActive {
    cursor: pointer;
    border-color: $primary_green;
}

.attachFileToReplyButton {
    display: flex !important;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
    width: 145px;
    height: 48px !important;
    margin-top: 12px;
    transition: none !important;

    &:hover {
        color: $primary_green !important;
        border-color: $primary_green !important;
    }
}

.attachFileIcon {
    margin-right: 12px;
}

.attachFileChosen {
    justify-content: space-between;
    width: 100%;
    background-color: #EAEAEA;
}

.chosenResponsibleBlock {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.chosenAgentAvatar {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin-right: 12px;
}

.chosenDepartmentColor {
    background: #546BD6;
    border-radius: 2px;
    width: 16px;
    height: 16px;
    margin-right: 12px;
}

.chosenResponsibleText {
    font-family: SF UI Text;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: var(--primary-green) !important;
    text-transform: uppercase;
    margin-right: 12px;
}

.fieldRequired {
    color: #EE6363 !important;
}

.quickReplyIcon {
    margin-right: 10px;
}

.option {
    &.rtl {
        direction: rtl;
    }
}