@import "src/styles/colorVars";

.secondPageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: auto;
  margin-top: 66px;
}

.uploadButton {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  padding-top: 24px;
  margin-bottom: 24px;
}

.button-help-text {
  display: flex;
  align-items: center;
}

.info-text {
  font-family: SF UI Text;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: -0.02em;
  margin: 0;

  color: $tertiary_black;
}

.info-circle {
  margin-right: 4px;

  svg {
    fill: $tertiary_black;
  }
}
