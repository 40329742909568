@import "src/styles/colorVars";
@import "src/styles/numbers";

.messages-search-item {
    padding: 16px 12px 12px 12px;
    width: 100%;

    &:hover {
        background: #eeeef1;
        cursor: pointer;
    }

    &.in-chat-search {
        max-height: 72px;

        .text {
            margin: 4px 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &:hover {
            background: #0000000f;
        }
    }

    @media (max-width: $mobile_width) {
        .text {
            overflow: hidden;
        }
    }
}

.messages-search-item-divider {
    margin: 5px 0 !important;
}

.in-chat-search-highlighted {
    color: var(--primary-color);
    font-weight: bold;
}