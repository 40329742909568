@value colors: "../../../../styles/colors.css";
@value (mainGreyColor, hoverGreyColor, greyColor, darkGreyColor) from colors;

.mainBottomAreaWrapper {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
}

.mainBottomAreaWrapper i {
    color: darkGreyColor;
}

.mainBottomAreaWrapper>div {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mainBottomAreaWrapper>div:first-child{
    width: 30%;
    border-right: 1px solid #ddd;
}

.mainBottomAreaWrapper>div:nth-child(2){
    width: 70%;
}

.quickReplies{
    display: grid;
    grid-template-rows: auto 1fr;
    width: 100%;
    border: 1px solid #ddd;
    position: absolute;
    bottom: 64px;
    left: 0;
    z-index: 1500;
    background: #fff;
    text-align: center;
    max-height: 522px;
    overflow: auto;
}

.quickReplies>div{
    text-align: left;
    width:100%;
    min-width: 100%;
}

.quickReplies>button {
    margin-top: 30px;
}


.displayNone{
    display: none;
}

.disableAddReply {
    display: none;
}

.quickRepliesField {
    display: flex;
    width: 100%;

    border-bottom: 1px solid #EAEAEA;
}

.quickRepliesBarWithSyncButton {
    width: 100%;
}
.quickRepliesBarWithoutSyncButton {
    width: 100%;
    height: 100%;
}

.quickRepliesTitleRight {
    display:flex;
    justify-content: space-between;
    align-items: center;
    width: initial;
    height:100%;
    z-index: 10;
}

.quickRepliesMainField {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 32px;
    padding: 0 24px;
    width: 100%;
    height: 64px;
    border-left: transparent 2px solid;
    background-color: white;
    z-index: 10;
}

.quickRepliesMainField:not(.quickRepliesDisabled):hover {
    border-left: 2px solid#21AE84;
    background: #f8f8f8;
    cursor: pointer;
}
.quickRepliesMainField:not(.quickRepliesDisabled):hover .coloredIcon svg{
    fill: #21AE84;
}

.quickRepliesMainField:not(.quickRepliesDisabled):hover .coloredIcon{
    color: #21AE84;
}

.coloredIcon {
    color: #DADADA;
    width: 20px;
    height: 20px;
}

.syncButton {
    height: 100%;
    text-align: center;
    border-left: 1px solid #ddd;
    background: greyColor;
    cursor: pointer;
}

.syncButton:hover {
    background: hoverGreyColor;
}

.syncButton svg {
    margin-top: 15px;
    width: 22px;
    height: 22px;
}

.storagedImg {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

.quickRepliesDisabled .titleQuickReplies {
    color: #ccc;
}

.checkboxGroup {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0 0 10px;
}

.quickRepliesTitleHolder{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;
    z-index: 10;
}

.quickRepliesTitleLeft {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
}

.addReplyButton {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.noRepliesBody, .noRepliesBodyText, .noRepliesContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.noRepliesBodyText {
    margin-bottom: 70px;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    letter-spacing: -0.02em;
    height: 50px;
    width: 452px;
    justify-content: space-between;
}

.noRepliesContainer {
    height: 100%;
    width: 100%;
}

.editReplyContainer {
    width: 197px;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid #EAEAEA;
    box-sizing: border-box;
    border-radius: 2px;
    margin-bottom: 48px;
}

.noRepliesIcon svg {
    margin-top: 79px;
    margin-bottom: 39px;
    color: #e5e5e5;
    height: 42px;
    width: 51.33px;
}

.titleReplies {
    padding: 20px 0;
}

.newReplyButton {
    margin: 20px 0;
    text-align: center;
}

.createReplyButton {
    height: 48px;
    width: 197px;
}

.quickRepliesDarkBackground {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.6;
    filter: alpha(opacity=60);
    z-index: 100;
}

.templateMessageTemplate {
    color: #EE6363;
}

.replies-or-templates-loading {
    font-size: 50px;
    display: block!important;
    margin: 15px auto;
    color: var(--primary-green);
}

@media (max-width: 1026px) {
    .quickReplies {
        bottom: 50px;
        max-height: calc(100vh - 50px);
    }
    .quickRepliesField {
        width: 100vw;
    }
    .quickRepliesMainField {
        height: 50px;
    }
}
