@import "src/styles/colorVars";

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 24px;
}

.avatar {
  width: 56px;
  height: 56px;
  border-radius: 50%;
}

.name {
  font-family: SF Pro Text;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: -0.33px;
  margin: 12px 0 8px 0;
}

.phone {
  font-family: SF Pro Text;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.15px;
  color: $secondary_black;
}

.notificationText {
  font-family: SF UI Text;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: -0.02em;
  max-width: 400px;
  margin-bottom: 50px;
}

.alarmText {
  color:$primary_red;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 24px;
}

.alertButtonText {
  font-family: SF UI Text;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #FFFFFF !important;
  text-transform: capitalize;
}

.preloader svg{
  color: white !important;
}
