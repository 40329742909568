@import 'src/styles/colorVars';
@import 'src/styles/numbers';

.edit-template-message {
    min-height: 80vh;
    font-size: 20px;
    font-weight: 400;
    min-width: 60vw;
}

.edit-template-message__header {
    height: 84px;
    border-bottom: 1px solid rgba(234, 234, 234, 0.7);
    display: grid;
    padding: 24px;
    grid-template-columns: max-content max-content 1fr;
    align-items: center;
    grid-column-gap: 10px;
}

.edit-template-message__icon {
    color: #21ae84;
}

.edit-template-message__container {
    overflow: hidden;
    height: 100%;
    position: relative;
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 8px;
    padding: 1rem 1.5rem;
    border-bottom: 1px solid rgba(234, 234, 234, 0.7);
    height: calc(80vh - 192px);

    @media (max-width: $mobile_sm_width) {
        display: block;
        grid-template-columns: 1fr;
        padding: 16px;
        overflow: auto;
    }
}

.edit-template-message__info {
    display: flex;
    align-items: center;
    padding: 12px;
    text-align: center;
    font-size: 14px;
    background: #f5f5f5;
    border-radius: 2px;
    height: 72px;
    margin-bottom: 16px;
}

.edit-template-message__footer {
    height: 104px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 35px;

    button[disabled] {
        width: 100%;
        height: 100%;
    }
}

.edit-template-message__button {
    width: 168px;
    height: 48px !important;
    flex-basis: auto;
    flex-grow: unset;
    margin-left: 24px;
}

.edit-template-message__input {
    display: inline-block;
    min-width: 90px;
    text-align: initial;
    line-height: 1;
    white-space: pre-wrap;
    border-bottom: 1px solid $primary_black;
    outline: 0;
    overflow: visible;
    font-size: 1.3rem;
    transition: border-color 0.2s;
}

.edit-template-message__text {
    text-align: right;
    white-space: pre-wrap;
}

[dir='ltr'] .edit-template-message__text {
    text-align: left;
}

[dir='rtl'] .edit-template-message__text {
    text-align: right;
}

.edit-template-message__advanced_settings_title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #383c3a;
    margin: 16px 0;
}

.edit-template-message__advanced_settings {
    width: 100%;
}

.edit-template__footerText {
    color: $tertiary_black;
    opacity: 0.5;
    white-space: pre-wrap;
    font-size: 16px;
}

@media (max-width: 1026px) {
    .edit-template-message {
        font-size: 1.2em;
    }
    .edit-template-message__info {
        padding: 8px;
        margin-bottom: 5px;
    }

    .edit-template-message__input {
        font-size: 0.9em;
    }

    .edit-template-message__text {
        font-size: 0.8em;
    }
}

.edit-template-content-left {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding-right: 8px;

    &.isRtl {
        direction: rtl;
        padding-left: 8px;
        padding-right: 0;

        .edit-template-message__advanced_settings_title,
        .edit-template-message__advanced_settings {
            text-align: right;
        }

        [class="ant-input-suffix"] {
            margin-left: 0;
            margin-right: 8px;
        }
    }

    @media (max-width: $mobile_sm_width) {
        overflow: hidden;
        padding-right: 0;
    }
}

.edit-template-content-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow-y: auto;
    height: 100%;

    @media (max-width: $mobile_sm_width) {
        overflow-y: hidden;
    }
}

.param-input {
    height: 48px !important;
    margin-bottom: 12px;

    input {
        height: 100% !important;
    }

    [class="ant-input-suffix"] {
        margin-left: 8px;
    }
}

.upload-block {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 12px;
    margin-top: 16px;
    margin-bottom: 32px;
}

.button-help-text {
    display: flex;
    align-items: center;
}

.info-text {
    font-size: 12px;
    line-height: 12px;
    letter-spacing: -0.02em;
    margin: 0;
    color: $tertiary_black;
}

.info-circle-icon {
    margin-right: 4px;

    svg {
        fill: $tertiary_black;
        width: 16px;
        height: 16px;
    }
}

.edit-template-message-upload-button {
    margin: 0;
}
