.facebook {
    button {
        cursor: pointer;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 0.5rem;
        padding: 0.5rem 1rem;
        margin-top: 0.5rem;
        font-size: 0.875rem;
        font-weight: 600;
        background-color: #3b82f6;
        color: #ffffff;
        transition: background-color 0.2s;
        border: 1px solid transparent;
        border-radius: 0.375rem;

        &:hover {
            background-color: #2563eb;
        }
    }

    svg {
        width: 20px;
        height: 20px;
        fill: currentColor;
    }

    span {
        font-size: 0.875rem;
        color: #ffffff;
    }
}

.microsoft {
    button {
        display: flex;
        align-items: center;
        background-color: white;
        border: 1px solid #8c8c8c;
        padding: 0.5rem 1rem;
        cursor: pointer;
        font-size: 15px;
        height: 40px;
        gap: 12px;

        &:hover {
            background-color: #f3f4f6;
        }
    }

    svg {
        width: 20px;
        height: 20px;
    }

    span {
        color: #5e5e5e;
        font-family: 'Segoe UI', sans-serif;
        font-weight: 600;
    }
}

.google {
    .gsi-material-button {
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        -webkit-appearance: none;
        background-color: WHITE;
        background-image: none;
        border: 1px solid #747775;
        -webkit-border-radius: 4px;
        border-radius: 4px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        color: #1f1f1f;
        cursor: pointer;
        font-family: 'Roboto', arial, sans-serif;
        font-size: 14px;
        height: 40px;
        letter-spacing: 0.25px;
        outline: none;
        overflow: hidden;
        padding: 0 12px;
        position: relative;
        text-align: center;
        -webkit-transition:
            background-color 0.218s,
            border-color 0.218s,
            box-shadow 0.218s;
        transition:
            background-color 0.218s,
            border-color 0.218s,
            box-shadow 0.218s;
        vertical-align: middle;
        white-space: nowrap;
        width: auto;
        max-width: 400px;
        min-width: min-content;
    }

    .gsi-material-button .gsi-material-button-icon {
        height: 20px;
        margin-right: 12px;
        min-width: 20px;
        width: 20px;
    }

    .gsi-material-button .gsi-material-button-content-wrapper {
        -webkit-align-items: center;
        align-items: center;
        display: flex;
        -webkit-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: nowrap;
        flex-wrap: nowrap;
        height: 100%;
        justify-content: space-between;
        position: relative;
        width: 100%;
    }

    .gsi-material-button .gsi-material-button-contents {
        -webkit-flex-grow: 1;
        flex-grow: 1;
        font-family: 'Roboto', arial, sans-serif;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        vertical-align: top;
    }

    .gsi-material-button .gsi-material-button-state {
        -webkit-transition: opacity 0.218s;
        transition: opacity 0.218s;
        bottom: 0;
        left: 0;
        opacity: 0;
        position: absolute;
        right: 0;
        top: 0;
    }

    .gsi-material-button:disabled {
        cursor: default;
        background-color: #ffffff61;
        border-color: #1f1f1f1f;
    }

    .gsi-material-button:disabled .gsi-material-button-contents {
        opacity: 38%;
    }

    .gsi-material-button:disabled .gsi-material-button-icon {
        opacity: 38%;
    }

    .gsi-material-button:not(:disabled):active .gsi-material-button-state,
    .gsi-material-button:not(:disabled):focus .gsi-material-button-state {
        background-color: #303030;
        opacity: 12%;
    }

    .gsi-material-button:not(:disabled):hover {
        -webkit-box-shadow:
            0 1px 2px 0 rgba(60, 64, 67, 0.3),
            0 1px 3px 1px rgba(60, 64, 67, 0.15);
        box-shadow:
            0 1px 2px 0 rgba(60, 64, 67, 0.3),
            0 1px 3px 1px rgba(60, 64, 67, 0.15);
    }

    .gsi-material-button:not(:disabled):hover .gsi-material-button-state {
        background-color: #303030;
        opacity: 8%;
    }
}
