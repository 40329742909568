@import "src/styles/numbers";

.messageList {
    display: flex;
    flex-flow: column nowrap;
    overflow-y: scroll;
    overflow-x: hidden;
    gap: 3px;
    position: absolute;
    height: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    z-index: 10;
    top: 0;
}

.messageList > :first-child {
    margin-top: auto;
}

.bottomBlock {
    margin-top: 8px;
    margin-bottom: 1px;
    min-height: 1px;
    height: 1px;
}

.loaderWrap {
    position: relative;
    width: 100%;
}

.loader {
    position: absolute!important;
    left: 50%;
    margin-left: -19px;
    background-color: #F3F3F3;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    padding: 5px!important;
    opacity: 0.9;
    z-index: 9999;
}

@media (max-width: $mobile_width) {
    .messageList {
        width: 100vw;
        height: 100%;
    }
}
