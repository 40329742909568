@import "src/styles/colorVars";

.teaserContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
}

.teaserContent {
    display: flex;
    flex-direction: column;
    font-size: 12px;
}

.teaserName {
    display: flex;
    align-items: center;
    text-transform: capitalize;
}

.teaserNumber {
}

.teaserIcon {
    align-self: flex-start;
    width: 22px;
    min-width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: $primary_green;
}

.copyIconBtn {
    &:hover svg {
        fill: $primary_blue;
    }
}