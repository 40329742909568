@import "src/styles/colorVars";
@import "src/styles/numbers";

.createNewTemplateButton {
    display: flex !important;
    align-items: center;
    gap: 8px;
    height: 48px !important;
    transition: none !important;

    & > span {
        margin: 0 !important;
    }

    &:hover {
        border-color: $primary_green_hover !important;

        svg, path {
            fill: $primary_green_hover;
        }
        strong {
            color: $primary_green_hover;
        }
    }
}

.createNewTemplateButtonText {
    color: var(--primary-green) !important;
}


.existingTemplates {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
}

.noTemplatesBody, .noTemplatesBodyText, .noTemplatesContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.noTemplatesContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}

.noTemplatesBody {
    margin-top: 10%;
}

.noTemplatesIcon svg {
    margin-top: 79px;
    margin-bottom: 39px;
    color: #e5e5e5;
    height: 100px;
    width: 100px;
    padding-bottom: 20px;
}

.noTemplatesBodyText {
    margin-bottom: 70px;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    letter-spacing: -0.02em;
    height: 50px;
    width: 452px;
    justify-content: space-between;
}

.templatesBottom {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 2px solid #EAEAEA;
    height: 86px;
    padding: 40px 20px;
}

.showDeletedBlock {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
    gap: 8px;
}

@media(max-width: $mobile_width) {
    .noTemplatesBodyText {
        width: 95vw !important;
    }
}

.bodyWrapper {
    display: grid;
    grid-template-rows: auto 1fr auto;
    height: calc(100% - 84px); // header height
}