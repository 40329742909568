.main-page-container {
  display: flex;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  position: fixed;
}

.main-page-layout {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;

  &.rtl {
    direction: rtl;
  }  
}

.no-internet-alert {
  position: absolute;
  top: 0;
}

@media (max-width: 1026px) {
  .main-page-layout {
    margin-top: 46px;
    height: calc(100% - 46px);
  }
  .ant-modal { width: 95% !important }
}
