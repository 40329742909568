@import "src/styles/colorVars";

.container {
    z-index: 100;
    width: fit-content;
    height: 26px;
    position: relative;
    bottom: 8px;
    right: 8px;
    max-width: 140px;

    &.in {
      right: 0;
      left: 8px;
    }
  }
  
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 0;
    min-width: 28px;
    height: 24px;
    border-radius: 50px;
    padding: 0 4px;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.07), 0 0 3px rgba(0, 0, 0, 0.04);
    border: 1px solid rgba(0, 0, 0, 0.05);
    background-color: $primary_white;
    cursor: pointer;

    svg {
      width: 12px;
      height: 12px;
      fill: $primary_green;
    }
}

.reactions {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 1rem;
  line-height: 20px;
}

.totalAmount {
  font-size: 12px;
  color: $tertiary_black;
}