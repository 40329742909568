@import "src/styles/numbers";
@import "src/styles/colorVars";

.composeContainer {
  min-height: $compose_height;
  padding: 12px 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background:rgba(255, 255, 255, 0.6);
  border-top: 1px solid #eeeef1;
  outline: none;

  &.previewMode {
    background: $primary_grey;
  }

  &.replyContainer:not(&.previewMode) {
    min-height: 2 * $compose_height;
  }
}

.compose {
  display: flex;
  align-items: flex-end;
  width: 100%;
}

.textAreaWrapper {
  flex: 100%;
  display: flex;
  align-items: center;
  margin-right: 4px;
}

.textareaInputContainer {
  border: none !important;
}

.textareaInput {
  flex: 100%;
  word-spacing: 1px;
  
  &[class*="ant-input"] {
    padding: 10px 10px;
    background-color: $primary_white;
    border-radius: 25px;

    &:focus, &:hover {
      background-color: $primary_white;
    }
  }

  &[disabled] {
    background: #f5f5f5;
    cursor: not-allowed;
  }
}

.compose .toolbarButton {
  color: #bbbbbf;
  margin-left: 15px;
}

.compose .toolbarButton:hover {
  color: #99999c;
}

.greyColor {
  color: $secondary_black;
}

.filesComposeButtons {
  display: flex;
  align-items: center;
  margin: 0 8px;
  padding-bottom: 5px;
}

.files {
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  width: 100%;

  svg {
    width: 16px;
    height: 16px;
  }

  [class="ant-upload"] {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    span {
      white-space: nowrap;
    }
  }

  &.rtl {
    [class="ant-upload"] {
      direction: rtl;
    }
  }

  [class="files-dropzone"] {
    display: flex;
    align-items: center;
    width: 100%;

    & > div {
      width: 100%;
    }
  }
}

.uploadMenu {
  [role="menuitem"] {
    height: 32px;
  }
}

.filesImage {
  width: 15px;
  height: 15px;
}

.emojisSelectButton {
  margin: 0 15px 0 15px;

  svg {
    pointer-events: none;
  }
}

.emojisSelectButton svg{
  height: 20px;
  width: 20px;
}

.leftComposeButtonsWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
  margin: 0 8px;
  padding-bottom: 5px;
}

.composeButtonWrapper {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  text-align: center;
  line-height: 32px;
  transition: 500ms;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  button {
    width: 32px;
    height: 32px;
    line-height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    transition: background 0.15s ease;
  }

  &:not(.disabled):not(:has(.sendButton)):hover,
  & > button:not(.disabled):hover {
      background: $primary_white;
  }

  &.disabled,
  button[disabled] {
    background: #dcdcdc !important;

    svg {
      fill: white;
    }

    path {
      stroke: white;
    }
  }

  &.previewMode {
    margin-left: 8px;
    height: 42px;

    button {
      background: var(--primary-color, $primary_blue);
      border-radius: 50%;
      border: none;

      & > span {
        color: $primary_white;
      }
    }
  }
}

.colorWhite {
  color: $primary_white !important;
}

.recordButton,
.sendButton {
  background: var(--background-color);

  &:hover {
    background: var(--background-color-hover) !important;
  }

  svg {
    font-size: 18px;
  }
}

@supports (backdrop-filter: blur(20px)) {
  .composeContainer {
    border: none;
    background-color: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(20px);
  }
}

@media (max-width: $mobile_width) {
  .composeContainer {
    width: 100vw;

    &.previewMode {
      width: 100%;
    }
  }
}
