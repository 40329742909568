@import 'src/styles/colorVars';
@import 'src/styles/numbers';

.wrapper {
    display: grid;
    grid-template-rows: auto 1fr;
    border-left: 1px solid $primary_white;
    height: 100%;

    &.rtl {
        border-right: 1px solid $primary_white;
    }
}

.header {
    display: flex;
    align-items: center;
    gap: 24px;
    background-color: $primary_white;
    padding: 16px;
    height: 72px;
    min-height: 72px;
    position: relative;
    min-width: 0px;
    z-index: 20;
}

.body {
    display: flex;
    flex-direction: column;
    padding: 0;
    position: relative;
    background-color: $primary_white;
}

.title {
    font-size: 16px;
    font-weight: bold;
    color: $primary_black;
}

.closeModal {
    width: 32px;
    height: 32px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    border-radius: 8px;

    svg {
        width: 24px;
        height: 24px;
    }

    svg path {
        fill: $primary_black;
    }

    &:hover {
        cursor: pointer;
        background-color: rgba(196, 196, 196, 0.3);
    }
}

.loadingWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    [class*='anticon'] {
        font-size: 48px;
        width: 48px;
        height: 48px;
        color: $primary_green !important;
    }
}

.resultsList {
    overflow-x: hidden;
    overflow-y: scroll;
    height: calc(100% - 98px);
    position: absolute;
    top: 98px;
    left: 0;
    right: 0;
    z-index: 2;

    & > [class*="in-chat-search"] {
        width: 100%;

        @media (max-width: $mobile_width) {
            padding-inline: 16px;
        }
    }

}

.searchInput {
    height: 40px;
    min-height: 40px;
    margin: 0 16px;
    width: calc(100% - 32px) !important;
}

.emptyContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
    height: 100%;
}

.emptyText {
    color: $primary_black;
    opacity: 0.8;
    text-align: center;
}

.resultsCount {
    display: flex;
    align-items: center;
    min-width: 60px;
    color: $primary_black;
}

.wrapper {
    [class='ant-input-suffix'] {
        [aria-label='close-circle'] svg {
            fill: $primary_black;
        }
        [aria-label='loading'] svg {
            fill: $primary_green;
        }
    }
}

.inputWrapper {
    display: flex;
    flex-direction: column;
}

.content {
    display: flex;
    padding: 16px;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.noResultsContentWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    svg {
        width: 32px;
        height: 32px;
        fill: $primary_black;
        opacity: 0.7;
    }
}

.numberOfResults {
    font-size: 12px;
    padding: 24px 16px 12px 16px;
    color: $primary_black;
    opacity: 0.7;
    border-bottom: 1px solid $primary_grey;
}