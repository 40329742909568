.csv-upload-failed-wrapper {
    display: flex;
    flex-direction: column;
    margin: 40px 0;
    width: 80%;
    align-items: center;
}

.file-load-error-main{
    background: #F5F5F5;
    border: 1px solid #EAEAEA;
    border-radius: 2px;
    display: flex;
    justify-content: space-between;
    min-height: 48px;
    height: 48px;
    padding: 3px 16px;
    width: 100%;

    display: flex;
    align-items: center;
}

.csv-upload-failed-info {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.csv-upload-failed-text {
    font-family: SF UI Text;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.02em;
    margin: 0 12px;

    color: #EE6363;
}

.csv-try-again-text {
    font-family: SF UI Text;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    margin: 0 30px 0 0;
    white-space: nowrap;

    color: #21AE84;
    cursor: pointer;
}

.csv-try-again-text:hover {
    cursor: pointer;
    text-decoration: underline;
}

.remove-csv {
    width: 32px;
    height: 32px;
    border-radius: 2px;
    text-align: center;
    line-height: 32px;
    transition: 500ms;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.remove-csv:hover {
    background: #dbdbdb;
}
