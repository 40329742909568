@import "src/styles/colorVars";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 176px;
  width: 100%;
  margin: auto;
  border-bottom: 2px solid $primary_grey_border;
  padding: 12px 0;
}

.photo {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.title {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  margin: 16px 0 8px
}

.text {
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  opacity: 0.7;
}
