@import "../../../styles/colorVars.scss";

.timestampBoxContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.timestampBox {
    background-color: rgba(225, 245, 254, 0.92);
    border-radius: 8px;
    padding: 5px 10px;
    min-width: 70px;
    max-width: 80%;
    margin-bottom: 10px;
    margin-top: 10px;
    position: relative;

    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12);

    display: flex;
    justify-content: center;
    align-items: center;
}

.timeDiffText {
    margin-bottom: 0;
    font-family: SF UI Text,"Segoe UI";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.02em;
    color: $primary_black;
}

.fixedDate {
    position: sticky;
    top: 0;
    z-index: 10;
    transform: translateY(-50px);
    transition: transform .2s ease-in-out;
}

.visible {
    transform: translateY(0);
}
