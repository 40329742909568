@import 'src/styles/colorVars';
@import 'src/styles/numbers';

.wrapper {
    width: 100%;
    margin: 4px 0;

    [class*='ant-collapse-borderless'] {
        background-color: transparent;
    }

    [class='ant-collapse-header'] {
        padding: 0 !important;
        display: grid !important;
        grid-template-columns: 1fr;

        &[aria-expanded='true'] {
            background-color: $secondary_grey;
        }
    }

    [class='ant-collapse-content-box'] {
        padding: 0 !important;
    }
}

.content {
    & > ul {
        padding: 4px 0;

        [class='MuiListItemText-root'] {
            padding-left: 31px;

            @media (max-width: $mobile_width) {
                padding-left: 47px;
            }
        }
    }

    svg {
        width: 18px;
        min-width: 18px;
        height: 18px;
    }

    svg,
    g,
    path {
        color: $secondary_black;
        fill: $secondary_black;
    }
}

.title {
    display: flex;
    align-items: center;
    gap: 16px;
}

.panelIcon {
    display: flex;
    align-items: center;
    font-size: 18px;
}
