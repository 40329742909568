@import 'src/styles/numbers';
@import 'src/styles/colorVars';

.add-agent-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: calc(100% - 80px);
}

.add-agent-labeled-input {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 20px;
    min-height: 60px;

    &-contacts {
        height: initial;
    }

    &.full-height {
        height: auto;
    }
}

.add-contacts-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 12px 0;

    label {
        font-size: 13px;
        color: $secondary_black;
    }

    [class="antd-select-selector"] {
        width: 100%;
    }
}

.add-agent-advanced-handle {
    cursor: pointer;
    width: fit-content;
}

.add-agent-advanced-handle-arrow {
    margin-left: 4px;
}

.add-agent-inputs-list {
    padding: 20px 50px 0 50px;

    height: calc(100% - 86px);

    overflow-x: hidden;
    overflow-y: scroll;
}


.ant-checkbox-wrapper.add-agent-checkbox {
    margin-bottom: 15px;
}

.add-agent-dropdown {
    [class="ant-select-selector"] {
        height: 100% !important;
        padding: 4px 12px !important;
    }
}

.ant-btn.add-agent-dropdown {
    min-width: 250px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.ant-btn.add-agent-dropdown,
.ant-btn.add-agent-dropdown:focus,
.ant-btn.add-agent-dropdown:active,
.ant-btn.add-agent-dropdown:hover {
    color: black !important;
    background-color: white !important;
}

.add-agent-error {
    font-family: SF UI Text;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: rgba(218, 63, 63, 0.96);
    margin-top: 3px;
}

.add-agent-bottom {
    display: flex;
    align-items: center;
    border-top: 2px solid #eaeaea;
    /*height: 86px;*/
    padding: 20px;
    justify-content: flex-end;
}

.add-agent-cancel-button,
.add-agent-save-button {
    min-height: 48px;
    height: 48px;
    width: 168px;
    border: 1px solid #eaeaea;
    z-index: 1;
}

.add-agent-cancel-button span,
.add-agent-save-button span {
    font-size: 13px;
}

.add-agent-save-button {
    margin-left: 24px;
}

.ant-select-dropdown {
    padding: 0;
}

.ant-select-item.ant-select-item-option.manage-departments-option {
    padding: 0;
}

.ant-btn.manage-departments-button {
    width: 100%;
    border-radius: 0;
}

.ant-select-item-option-content {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.ant-select-selection-item-content {
    display: flex !important;
    flex-direction: row;
    align-items: center;
}

.select-department-color {
    min-width: 8px;
    height: 8px;
    display: block;
    float: left;
    margin-right: 4px;
}

.ant-select-item-option-selected {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.add-agent-admin-text {
    text-transform: capitalize;
}

/*Move to common select and reuse everywhere*/
.ant-select-selector {
    width: auto !important;
}
.ant-select-selection-item {
    justify-content: left !important;
}

.show-contacts-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-top: 8px;

    [class="ant-list-item"] {
        padding: 4px 8px !important;

        [class="ant-list-item-meta"] {
            align-items: center;

            h4 {
                margin-bottom: 0;
            }
        }
        
        [class*="ant-avatar-image"] {
            width: 22px;
            height: 22px;
        }
    }
}

.remove-cross {
    border-radius: 2px;
    text-align: center;
    transition: 500ms;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    svg {
        width: 12px;
        height: 12px;

        path {
            fill: $primary_red;
        }
    }


    &:hover {
        background: #F5F5F5;
    }
}

.channel-select {
    [class="ant-select-selector"] {
        padding: 0 12px !important;
        height: 32px !important;
    }
    [class="ant-select-arrow"] {
        right: 16px;
    }
}

.disabled-switch {
    width: fit-content;
    max-width: 50px;

    &[class*="ant-switch-checked"] {
        background: $primary_green !important;
    }
}

.temporary-user-container {
    display: flex;
    align-items: center;
    gap: 4px;
}

.temporary-user-date {
    font-weight: bold;
}