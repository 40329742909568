@import "src/styles/colorVars.scss";

.switch {
  min-height: 24px;
  width: 40px;
  background-color: $primary_white !important;
  border: 1px solid $primary_grey !important;
}

.checked {
  :global {
    .ant-switch-handle::before {
      background-color: $primary_green !important;
    }
  }
}
