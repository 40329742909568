
.wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.loadingWrapper {
    display: flex;
    position: relative;
    height: 100%;
}

.forwardMessageButton {
    height: auto !important;
    padding: 10px 30px !important;
    margin-left: 24px;
    border: 1px solid #eaeaea !important;
}