@import "src/styles/colorVars";
@import "src/styles/numbers";

.chatSearchIcon {
    padding-left: 18px;
}

.chatSearchIcon svg{
    height: 20px;
    width: 20px;
    fill: grey;
}

.input {
    margin-left: 12px;
    min-width: 180px;
}

.search {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    gap: 12px;
    position: relative;
}

.cleanSearchInput {
    height: 20px;
    width: 20px;
    position: absolute;
    right: 15px;
    cursor: pointer;
}

.filtersWrapper {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: 16px;
    padding: 16px 20px;
    border-bottom: 1px solid $primary_grey_border;

    @media (max-width: $mobile_sm_width) {
        column-gap: 8px;
    }
}