@import "src/styles/colorVars";

.title {
    font-size: 16px;
    font-weight: 500;
    color: $primary_black;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 16px;
    left: 25px;

    &.rtl {
        direction: rtl;
        left: unset;
        right: 25px;
        text-align: right;
    }
}
