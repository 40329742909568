.addReplyWrapper {
  background-color: white;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.titleRepliesDeleteConfirmation {
  border-bottom: 2px solid #EAEAEA;
  /*height: 87px;*/
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 32px;
}

.addTitleReplies, .titleRepliesDeleteConfirmation {
  justify-content: flex-start;
}

.titleRepliesDeleteConfirmation::after {
  content: '';
  border-bottom: 2px solid #E26565;
  width: 56px;
  left: 32px;
  position: absolute;
  display: block;
  top: 72px;
}

.redDeleteIcon {
  color: #E26565;
  display: flex;
  align-items: center;
}

.repliesAddTitleText {
  font-size: 20px;
  margin-left: 17px;
}

.repliesTextEditorWrapper {
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  height:100%;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.deleteReplyHolder {
  width: 656px;
  background-color: #F5F5F5;
  margin-top: 32px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 87px;
}

.deleteReplyText {
  width: 532px;
  overflow-wrap: break-word;
  margin-left: 40px;
  height: 100%;
  display: flex;
  align-items: center;
}

.deleteReplyText {
  width: 532px;
  align-self: flex-start;
  padding: 20px 0;
}

.deleteReplyText span {
  width:100%;
}

.addReplyBottom {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 2px solid #EAEAEA;
  height: 86px;
  padding: 40px 20px;
}

.addReplyCancelButton, .addReplySaveButton, .addReplyDeleteButton{
  height: 48px !important;
  width: 168px;
  border: 1px solid #EAEAEA;
  z-index: 1;
}

.addReplyCancelButton span, .addReplySaveButton span, .addReplyDeleteButton span{
  font-size: 13px;
}

.addReplyDeleteButton {
  margin-left: 24px;
}

.addReplyDeleteButton{
  :global{
    .ant-typography {
      color: white;
    }
  }
}