.filterDepartmentSelect {
    width: 100%;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);

    [class="ant-select-selection-item"] {
        gap: 12px;

        img {
            display: none;
        }

        &::after {
            content: none !important;
        }
    }

    &.rtl {
        [class="ant-select-selection-item"] {
            padding-left: 18px !important;
            justify-content: right !important;
        }
        [class="ant-select-arrow"] {
            right: auto;
            left: 18px;
        }
    }
}

.filterDepartmentColor {
    min-width: 16px;
    height: 8px;
    display: block;
}

.filterDepartmentsText {
    margin: 0;
}

.filterDepartmentsContent {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 12px;
}


.filterDepartmentsOption {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
}