.departmentsSelector {
    width: 100%;
    min-width: 156px;

    [class='ant-select-selector'] {
        padding: 0 12px !important;
    }

    [class='ant-select-selection-item-content'] {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    &.rtl {
        &:has([class="ant-select-arrow"]) {
            [class='ant-select-selection-item'] {
                padding-right: 0 !important;
                padding-left: 18px !important;
            }

            [class="ant-select-arrow"] {
                right: auto;
                left: 18px;
            }
        }

        [class='ant-select-selection-item'] {
            direction: rtl;
            justify-content: right !important;

            &::after {
                content: none !important;
            }
        }
    }
}

.color {
    min-width: 8px;
    height: 8px;
    display: block;
    float: left;
}

.option {
    &.rtl {
        direction: rtl;
    }

    & > div {
        display: flex;
        align-items: center;
        gap: 8px;
    }
}
