@value colors: "../../../../styles/colors.css";
@value (mainBlueColor, mainGreyColor, hoverGreyColor) from colors;


.editQuickRepliesWrapper h1 {
    text-transform: capitalize;
    text-align: center;
    font-weight: 800;
    width: 100%;
    height: 100%;
}

.editQuickRepliesWrapper, .AddReplyWrapper {
    background-color: white;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.quickRepliesField {
    display: flex;
    justify-content: space-between;
}

.addNewRepliesField {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.quickRepliesField>:nth-child(2)>i {
    margin: 12px 4px 0 4px;
}


.addNewRepliesField>div:first-child {
    width: 100%;
    align-self: flex-end;
}

.addNewRepliesField>div:first-child input {
    width: 100%;
    outline: none;
    border: none;
    border-bottom: 1px solid #d3d3d3;
}

.mediaQueryField {
    margin-top: 70px;
}

.mediaQueryTable {
    margin: 30px 0;
}

.uploaderFormWrapper {
    text-align: center;
}

.errorField {
    color: red;
}

.storagedImg {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

.deleteTableButton {
    color: mainBlueColor;
    cursor: pointer;
}

.existingReplies{
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
}

.managerReply{
    cursor: pointer;
    border-left: transparent 2px solid;
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 100px;
}

.managerReply:hover {
    border-left: #21AE84 2px solid;
    background-color: #F8F8F8;
}

.managerReply:hover span {
    color: #636766;
}

.replyNameSpaceLeft {
    width: 532px;
    overflow-wrap: break-word;
    margin-left: 40px;
    height: 100%;
    display: flex;
    align-items: center;
}

.replyNameSpaceLeft {
    flex-direction: column;
    padding-bottom: 31px;
}

.replyNameSpaceRight {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    padding-right: 40px;
    align-self: baseline;
    margin-top: 20px;
    color: #DADADA;
}

.quickRepliesFieldActive {
    background: hoverGreyColor;
}

.buttonsWrapper{
    margin-top: 25px;
    text-align: center;
}

.repliesTextEditor textarea {
    border: none;
    outline: none;
    max-height: 148px;
}

.textEditorBoxEmoji {
    font-size: 18px;
    line-height: 18px;
    cursor: pointer;
    margin: 20px 0 0 20px;
}

.replyEditBoxWrapper{

}

.noRepliesBody, .noRepliesBodyText, .noRepliesContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

.noRepliesBody {
    margin-top: 10%;
}

.noRepliesContainer {
    display: block;
}

.noRepliesIcon svg {
    color: #e5e5e5;
    height: 100px;
    width: 100px;
    padding-bottom: 20px;
}

.titleReplies, .addTitleReplies, .titleRepliesDeleteConfirmation {
    border-bottom: 2px solid #EAEAEA;
    /*height: 87px;*/
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 32px;
}

.addTitleReplies, .titleRepliesDeleteConfirmation {
    justify-content: flex-start;
}

.titleReplies::after {
    content: '';
    border-bottom: 2px solid #30C498;
    width: 56px;
    left: 32px;
    position: absolute;
    display: block;
    top: 72px;
}

.addTitleReplies::after, .titleRepliesDeleteConfirmation::after {
    content: '';
    border-bottom: 2px solid #30C498;
    width: 56px;
    left: 32px;
    position: absolute;
    display: block;
    top: 72px;
}

.titleRepliesDeleteConfirmation::after {
    border-bottom: 2px solid #E26565;
}

.repliesTitleText {
    font-size: 20px;
    margin-left: 30px;
}

.repliesAddTitleText {
    font-size: 20px;
    margin-left: 17px;
}

.ReplyBottom, .addReplyBottom {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 2px solid #EAEAEA;
    /*width: 100%;*/
    height: 86px;
    padding: 40px 20px;
}

.addReplyBottom {
    justify-content: flex-end;
}

.addNewReplyTitle::after, .deletingReplyTitle::after {
    content: '';
    border-bottom: 2px solid #30C498;
    width: 56px;
    left: 32px;
    position: absolute;
    display: block;
    top: 82px;
}

.deletingReplyTitle::after {
    border-bottom: 2px solid #EE6363;
}

.addReplyCancelButton, .addReplySaveButton, .addReplyDeleteButton{
    height: 48px !important;
    width: 168px;
    border: 1px solid #EAEAEA;
    z-index: 1;
}

.addReplyCancelButton span, .addReplySaveButton span, .addReplyDeleteButton span{
    font-size: 13px;
}

.addReplySaveButton, .addReplyDeleteButton {
    margin-left: 24px;
}

.addReplyDeleteButton .ant-typography {
    color: white;
}

.textEditorBox{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.repliesTextEditorBody {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    border: 1px solid #DEDEDE;
    border-radius: 2px;
    width: 100%;
    height: 156px;
}

.replyText {
    font-size: 13px;
    color: #747775;
    padding: 32px 0 0 0;
    display: block;
    width: 100%;
    text-align: justify;
}

.contentWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    height:100%;
    max-height: 100%;
    max-width: 520px;
    padding: 0 16px;
    overflow-y: auto;
    overflow-x: hidden;
}

.replyNameButtonEdit, .replyNameButtonDelete {
    padding: 16px 16px 17.33px 17.33px;
    border-radius: 2px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.12);
    background-color: #FFFFFF;
}

.replyNameButtonEdit {
    margin-right: 8px;
}

.replyNameButtonEdit:hover svg{
    fill: #21AE84;
}

.replyNameButtonDelete:hover svg{
    fill: #E26565;
}

.attachFileButton {
    font-size: 14px;
    height: 48px;
    margin-top: 8px;
}

.attachWrapper {
    margin: 24px 0;
    display: flex;
    flex-direction: column;
}

.attachTitle span {
    color: rgba(56, 60, 58, 0.7);
    font-size: 13px;
}

.pdfContainer {
    width: 100%;
    height: 100%;
    padding: 0 38px 20px 38px;
}

.attachedFileItem{
    width: 100%;
    height: 48px;
    margin-top: 8px;
    background: #F5F5F5;
    border: 1px solid #EAEAEA;
    box-sizing: border-box;
    border-radius: 2px;
    display: flex;
    align-items: center;
}
.attachedFileIcon{
    margin-left: 22px;
}
.attachedFileText{
    margin-left: 14px;
}
.attachedFileErrorText{
    margin-left: 14px;
    color: #EE6363;
}
.attachedFileItem .attachedFileProgress{
    margin: 0 20px;
}
.attachedFileItem .attachedFileRemoveButton{
    margin: 0 20px 0 auto;
    border-radius: 2px;
    box-sizing: border-box;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.attachedFileRemoveButton:hover,.attachedFileRemoveButton.active{
    background: #EBEBEB;
}
.attachedFileRemoveButton:hover svg,.attachedFileRemoveButton.active svg{
    fill: #EE6363;
}
.tooltip{
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 24px;
    padding: 0 24px;
}
.tooltip .tooltipQuestion{
    color: #B4B4B4;
}
.tooltip .tooltipCancel{
    color: #B4B4B4;
    cursor: pointer;
}
.tooltip .tooltipDelete{
    color: #FF7E7E;
    cursor: pointer;
}

.emojiPicker {
    position: absolute;
    z-index: 2;
}

.quick-replies-loader-container {
    position: absolute;
    left: 0;
    top: 89px;

    width: 100%;
    height: calc(100% - 89px);

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: white;
}

.quick-replies-loader-spinner svg{
    height: 100px;
    width: 100px;
    color: var(--primary-green);
}

.department-labeled-input {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 20px;
    height: 60px;
    width: 100%;
}

.department-dropdown {
    min-width: 250px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.department-color {
    min-width: 8px;
    height: 8px;
    display: block;
    float: left;
    margin-right: 4px;
}
