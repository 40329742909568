.emojiPickerContainer {
    width: 344px;
    position: absolute;
    bottom: 75px;
    left: 6px;
    z-index: 100;
}

.picker {
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 100%
}
