@import 'src/styles/colorVars.scss';
@import 'src/styles/numbers.scss';

@keyframes pulse {
    0% {
        transform: scale(0.9);
        box-shadow: 0 0 0 0 rgba(15, 184, 120, 0.7); // $primary__green as rgba
    }
    70% {
        transform: scale(1);
        box-shadow: 0 0 0 8px rgba(15, 184, 120, 0);
    }
    100% {
        transform: scale(0.9);
    }
}

.dashboard__root {
    width: calc(100vw - #{$sidebar_width});
}

.dashboard__paperAnchorLeft {
    margin-left: $sidebar_width;
    width: 100%;
    max-width: calc(100vw - #{$sidebar_width});

    @media (max-width: $mobile-width) {
        margin-left: 0px;
        max-width: 100vw;
    }
}

.dashboard__paperAnchorRight {
    margin-right: $sidebar_width;
    width: 100%;
    max-width: calc(100vw - #{$sidebar_width});

    @media (max-width: $mobile-width) {
        margin-right: 0px;
        max-width: 100vw;
    }
}

.dashboard__container {
    display: grid;
    grid-template-areas: 'header' 'overview' 'main';
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, auto) 1fr;
    align-items: center;
    overflow-y: auto;

    &.rtl {
        [aria-label="right"][class*="ant-collapse-arrow"] {
            margin-left: 12px;
            margin-right: 0 !important;

            svg {
                transform: rotate(180deg);
            }
        }
    }
}

.dashboard__header {
    grid-area: header;
    height: $header_height;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 32px;
    background-color: $primary_white;
    position: sticky;
    top: 0;
    z-index: 10;

    @media (max-width: $desktop-width) {
        position: relative;
        top: inherit;
    }
}

.dashboard__header--dropdown {
    svg {
        path {
            fill: $primary_black;
        }
    }

}

.dashboard__overview {
    grid-area: overview;
    width: 100%;
    height: 100%;
}

.dashboard__main {
    grid-area: main;
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

.overview__container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 8px;
    padding: 8px;
    background-color: $primary_blue;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    position: sticky;
    top: $header_height;
    z-index: 10;

    @media (max-width: $desktop-width) {
        position: relative;
        top: inherit;
    }

    [class*='ant-skeleton-active'] {
        *::after {
            background: linear-gradient(
                90deg,
                hsl(0deg 0% 48% / 20%) 25%,
                hsl(0deg 0% 0% / 24%) 37%,
                hsla(0, 0%, 74.5%, 0.2) 63%
            ) !important;
        }
    }
}

.box__container {
    border-radius: 4px;
    box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.25);
    background: rgba(0, 0, 0, 0.2);
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
    
    @media (min-width: $desktop_width) {
        padding: 16px;
    }
}

.box__title {
    align-self: start;
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    width: 100%;
    gap: 8px;
    margin-bottom: 8px;
}

.box__value {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100% - 28px);
    overflow: hidden;

    & > div {
        text-align: center;
    }

    &--compact {
        align-items: flex-start;

        & > div {
            width: 80%;
        }
    }
}

.box__value__contentContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $primary_black;

    &--inverted {
        color: $primary_white;
    }
}

.box__icon {
    width: 28px;
    height: 28px;

    & > span,
    svg,
    path {
        width: 100%;
        height: 100%;
        fill: $primary_black;
    }

    &--inverted {
        svg,
        path {
            fill: $primary_white;
        }
    }
}

.box__text {
    font-size: 16px;
    line-height: 18.75px;
    color: $primary_black;

    &--inverted {
        color: $primary_white;
    }
}

.box__value--simple {
    font-size: 32px;
    line-height: 56px;
    color: $primary_black;

    &--inverted {
        color: $primary_white;
        font-size: 96px;
        line-height: 112.5px;
    }

    @media (min-width: $desktop_width) {
        font-size: 48px;

        &--inverted {
            font-size: 64px;
        }
    }
}

.box__value--over {
    div[class*='ant-divider'] {
        margin: 16px 0;
    }

    &--inverted {
        div[class*='ant-divider'] {
            border-color: $primary_white !important;
            margin: 24px 0;
        }
    }
}

.over__title {
    font-size: 24px;
    line-height: 28px;
    font-weight: normal;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
    color: $primary_black;

    &--inverted {
        font-size: 36px;
        line-height: 42px;
        font-weight: 600;
        color: $primary_white;

    }

    &--time {
        font-size: 24px;
        line-height: 28px;
        white-space: nowrap;
    }

    &--noData {
        text-shadow: none;
    }

    @media (min-width: $desktop_width) {
        font-size: 32px;
        line-height: 38px;

        &--inverted {
            font-size: 56px;
            line-height: 68px;
        }

        &--time {
            font-size: 48px;
            line-height: 56px;
        }
    }
}

.under__title {
    display: flex;
    align-items: center;
    font-size: 20px;
    line-height: 23px;
    color: $primary_black;

    svg {
        margin-right: 8px;
    }

    &--inverted {
        font-size: 24px;
        line-height: 28px;
        color: $primary_white;
    }

    &--noData {
        font-size: 20px;
        line-height: 23px;
        text-shadow: none;
    }

    @media (min-width: $desktop_width) {
        &--inverted {
            font-size: 32px;
            line-height: 37.5px;
        }
    }
}

.over-under__subtitle {
    font-size: 12px;
    align-self: flex-end;
    margin-left: 4px;
    color: $primary_black;
    white-space: nowrap;

    &--up {
        position: relative;
        bottom: 4px;
    }

    &--inverted {
        font-size: 16px;
        color: $primary_white;
    }
}

.dashboard__header--leftContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 72px;
}

.dashboard__header--titleContainer {
    display: flex;
    flex-direction: column;
    line-height: 24px;
}

.dashboard__header--title {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 24px;
    letter-spacing: 0.4px;
    font-weight: 600;
}

.title__currentDate {
    font-size: 12px;
    font-weight: 400;
    color: $tertiary_black;
    letter-spacing: -0.1px;
}

.dashboard__header--input {
    display: flex;
    align-items: center;
    position: relative;
    height: 48px;
    width: clamp(250px, 450px, 30vw);

    &:hover {
        & > svg {
            visibility: visible;
        }
    }
}

.dashboard__header--datePicker {
    height: 100%;
    width: 100%;
    box-shadow: none !important;

    span[class='ant-picker-suffix'] {
        margin-left: 0;
        margin-right: 8px;
        order: -1;
    }

    div[class*='ant-picker-input'] {
        & > input {
            font-size: 13px;

            &::placeholder {
                padding-left: 8px;
            }
        }
    }

    div[class='ant-picker-range-separator'] {
        padding-right: 8px;
        padding-left: 0;
        margin-left: -20px;
    }

    &:hover,
    &:active {
        border-color: $primary_green !important;

        span[class='ant-picker-suffix'] > svg > path {
            fill: $primary_green !important;
        }
    }
}

.dashboard__header--datePickerDropdown {
    td[class*='ant-picker-cell-in-view'] {
        &[class*='ant-picker-cell-today'] div[class='ant-picker-cell-inner'] {
            color: $primary_green;

            &:before {
                border-color: $primary_green;
                border-radius: 50%;
            }
        }

        &[class*='ant-picker-cell-in-range']:before,
        &[class*='ant-picker-cell-range-end']:not([class*='ant-picker-cell-range-end-single']):before,
        &[class*='ant-picker-cell-range-start']:not([class*='ant-picker-cell-range-start-single']):before {
            background-color: $secondary_grey;
        }

        &[class*='ant-picker-cell-range-hover'] [class*='ant-picker-cell-inner']:after,
        &[class*='ant-picker-cell-range-hover-start'] [class*='ant-picker-cell-inner']:after,
        &[class*='ant-picker-cell-range-hover-end'] [class*='ant-picker-cell-inner']:after {
            border-color: $primary_green !important;
        }

        &[class*='ant-picker-cell-range-end'] [class*='ant-picker-cell-inner'],
        &[class*='ant-picker-cell-range-start'] [class*='ant-picker-cell-inner'],
        &[class*='ant-picker-cell-selected'] [class*='ant-picker-cell-inner'] {
            background-color: $primary_green;
            border-color: $primary_green;
            border-radius: 50% !important;
        }
    }
}

.dashboard__header--inputIcon--clear {
    position: absolute;
    right: 4px;
    width: 28px;
    height: 28px;
    background-color: transparent;
    border-radius: 4px;
    padding: 8px;
    fill: $primary_red;
    cursor: pointer;
    z-index: 10;
    visibility: hidden;

    &:hover {
        background-color: $secondary_grey;
    }
}

.dashboard__header--inputIcon {
    margin-right: 8px;
    margin-left: 0;
    fill: $tertiary_black;

    svg {
        fill: $tertiary_black;
    }
}

.dashboard__header--actionWrapper {
    display: flex;
    align-items: center;
    gap: 16px;
}

.dashboard__header--moreIcon {
    svg {
        transform: rotate(90deg);
        width: 32px;
        height: 32px;
    }
}

.title__live {
    text-transform: uppercase;
    color: $tertiary_green;
}

.title__dot {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: $primary_green;
    animation: pulse 2s infinite;
}

.main__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    [class*='ant-collapse-ghost'] {
        border: 1px solid transparent;
    }

    @media (max-width: $desktop-width) {
        overflow-y: initial;
    }

    &--agents {
        background-color: $primary_blue;
    }
}

.main__panel--all {
    div[class*='ant-collapse-content'] {
        margin-bottom: 16px;
    }

    div[class='ant-collapse-content-box'] {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: auto;
        gap: 8px;
        padding: 8px;

        & > div {
            background-color: transparent;
            box-shadow: none;
            border: 1px solid transparent;
        }
    }

    span[class='ant-collapse-header-text'] {
        font-size: 24px;
    }

    span[class*='ant-collapse-arrow'] svg {
        width: 20px;
        height: 20px;
    }

    div[class='ant-collapse-header'] {
        align-items: center !important;
    }

    div[class='ant-collapse-expand-icon'] {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    div[class='ant-collapse-extra'] {
        position: absolute;
        width: calc(100% - 16px);
    }

    &--agents {
        div[class='ant-collapse-header'] {
            background-color: $secondary_blue;
            padding: 0 8px !important;
            margin: 12px 8px;
            margin-bottom: 0;
            min-height: 32px;
        }

        div[class='ant-collapse-content-box'] {
            background-color: $secondary_blue;
            margin: 0 8px;

            & > div {
                background-color: $primary_white;
            }
        }
    }
}

.box__summary--container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 48px;
    padding: 6px 8px 6px 26px;
    align-items: end;
    margin-left: auto;

    @media (max-width: $desktop-width) {
        display: none;
    }
}

.box__summary--header {
    grid-column: 2 / 6;
    display: grid;
    grid-template-columns: repeat(3, 1fr) minmax(265px, 1fr) 1fr 150px;
    padding: 8px 0px;
    align-items: end;
    border-bottom: 1px solid transparent;
    border-top: 1px solid transparent;

    &__agents {
        grid-template-columns: repeat(3, 1fr) minmax(265px, 1fr) 1fr;
    }
}

.box__summary--item {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;

    &:not(:last-child) {
        border-right: 1px solid transparent;
    }

    &--red {
        border-right-color: $primary_red !important;
    }

    &--blue {
        border-right-color: $primary_blue !important;
    }

    &--green {
        border-right-color: $primary_green !important;
    }
}

.box__summary--skeleton {
    min-width: 50px !important;
    width: 50px !important;
    height: 20px !important;
    margin-left: 10px;
    margin-top: 2.57px; // antd vertical-align fix
}

.box__summary--item-icon {
    display: flex;
    align-items: center;

    svg {
        width: 14px;
        height: 14px;

        path {
            fill: $primary_black;
        }
    }
}

.box__summary--item-title {
    font-size: 16px;
    font-weight: 400;
    color: $primary_black;
}

.box__summary--item-value {
    font-size: 16px;
    font-weight: 500;
    color: $primary_black;

    &--compact {
        font-size: 14px;
    }
}

.box__value--chartContainer {
    position: relative;
    height: 100%;
    min-height: 100px;
}

.box__value--chart {
    display: grid;
    grid-template-rows: 1fr auto;
    align-items: center;
    gap: 16px;
    height: calc(100% - 28px);

    div[data-chart-source-type='G2Plot'] {
        height: 100% !important;
    }

    svg:not([class="ant-empty-img-simple"]) {
        filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.25));
        position: relative;
        top: 4px;

        & > path {
            fill: $tertiary_yellow;
        }
    }

    &--compact {
        grid-template-rows: auto auto;
        grid-template-columns: 40% 60%;
        width: 100%;
        max-width: 100%;
        row-gap: 0;
        column-gap: 8px;
    }

    &--grid {
        grid-row: 1 / 3;
    }
}

.box__value--chart__small {
    display: flex;
    align-items: center;
    align-self: end;
    font-size: 16px;
    margin-bottom: 4px;

    &__noBot {
        grid-row: 1 / 3;
        align-self: center;
        margin-left: 24px;
    }
}

.box__value--chart--smallLabel {
    font-size: 12px;
    align-self: flex-end;
}

.box__value__contentContainer {
    align-self: flex-start;

    &--withIcon {
        justify-self: flex-start;
        margin-left: 8px;
    }
}

.box__value--gauge {
    display: grid;
    grid-template-rows: minmax(100px, 1fr) auto;
    align-items: center;
    height: 100%;
    width: 80%;

    div[data-chart-source-type='G2Plot'] {
        height: 100% !important;
    }
}

.box__value--gauge--content {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    align-items: center;
    place-items: center;
    position: relative;
    bottom: 70%;

    & > svg {
        width: 25px;
        height: 25px;
        margin-left: 4px;
    }

    &--inverted {
        bottom: 60px;
    }
}

.box__value--gauge__value {
    font-size: 32px;
    line-height: 37.5px;
    letter-spacing: -1px;

    &--inverted {
        color: $primary_white;
    }
}

.box__value--gauge__valueSmall {
    font-size: 11px;
    line-height: 12.89px;
    margin-top: 8px;

    &--inverted {
        color: $primary_white;
    }
}

.sla-modal__container {
    div[class='ant-modal-content'] {
        display: grid;
        min-height: 700px;

        @media (max-width: $desktop-width) {
            min-height: 500px;
        }
    }

    div[class='ant-modal-body'] {
        padding: 0;
    }
}

.sla-modal__wrapper {
    display: grid;
    grid-template-rows: auto 1fr auto;
    gap: 16px;
    height: 100%;
}

.sla-modal__header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $primary_grey_border;
    position: relative;
    padding: 32px;

    &--rtl {
        direction: rtl;
        text-align: right;
    }
}

.sla-modal__header--title {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;
}

.sla-modal__greenBorder {
    position: absolute;
    bottom: -1px;
    left: 32px;
    height: 2px;
    width: 56px;
    background-color: $primary_green;

    &--rtl {
        left: auto;
        right: 32px;
    }
}

.sla-modal__body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px;
    gap: 16px;

    button[class*='ant-switch-checked'] {
        background-color: $primary_green;
    }

    &--rtl {
        direction: rtl;
        text-align: right;
    }
}

.sla-modal__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 32px;
    gap: 24px;
}

.sla-modal__pendingContainer {
    display: flex;
    align-items: center;
    gap: 16px;
}

.footer__buttons {
    height: 40px !important;
    min-width: 114px !important;
}

.sla-modal__extraInfo {
    font-size: 14px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.25);
    margin-top: 8px;
}

.empty-placeholder {
    margin: 0 !important;

    &--inverted {
        div[class='ant-empty-description'] {
            color: $primary_white !important;
        }
    }
}

.divider--small {
    margin: 8px 0 !important;
}

.chart__noData {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        filter: none;
    }
}

.main__panel--resolvedSummary {
    display: flex;
    align-items: center;
    gap: 8px;
}

.sla-modal__section {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 24px;

    [class='ant-picker'] {
        min-width: 200px;
    }
}

.sla-modal__title {
    font-size: 16px;
    line-height: 16px;
    font-weight: 600;
}

.sla-modal__departmentsManagerButton {
    border-color: $primary_green !important;
}

.agents__onlineContainer {
    display: flex;
    align-items: center;
    gap: 4px;
    background: transparent;
    height: 36px;
    background: linear-gradient(90deg, #2c3871 0%, #{$primary_blue} 100%);
    padding: 0 12px;
    text-transform: capitalize;

    font-weight: 500;
    font-size: 20px;
    color: $primary_white;
}

.agents__onlineValue {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
}

.main__panel--agentsHeader {
    display: flex;
    align-items: center;
    gap: 8px;
    color: $secondary_black;

    svg {
        width: 24px;
        height: 24px;
    }

    & > div:first-child {
        display: flex;
        align-items: center;
        position: relative;
    }
}

.main__panel--online {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 10px !important;
    border-radius: 4px !important;
}

.box__notAvailable {
    font-size: 20px;
}

.main__panel--notAvailable {
    font-size: 12px;
    color: $secondary_black;
}

.download-data-modal__container {
    max-height: 930px;
    flex-direction: column;
}

.download-data-modal__header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $primary_grey_border;
    position: relative;
    height: 56px;

    &--rtl {
        direction: rtl;
        text-align: right;
    }
}

.download-data-modal__header--title {
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    letter-spacing: -0.28px;
}

.download-data-modal__greenBorder {
    position: absolute;
    bottom: -1px;
    left: 32px;
    height: 2px;
    width: 56px;
    background-color: $primary_green;

    &--rtl {
        left: auto;
        right: 32px;
    }
}

.download-data-modal__body {
    display: flex;
    flex-direction: column;
    padding: 24px 24px 0px 24px;
    justify-content: center;
    align-items: center;
    gap: 24px;
    align-self: stretch;
}

.download-data-modal__pickDateWrapper {
    display: flex;
    flex-direction: column;
    height: 152px;
    justify-content: center;
    align-items: center;
    gap: 24px;
    flex: 1 0 0;
}

.download-data-modal__buttonsGroup {
    display: flex;
    flex-direction: row;

    &--rtl {
        flex-direction: row-reverse;
    }
}

.download-data-modal__dateRange {
    display: flex;
    padding: 14px 10px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    height: 48px;

    &--rtl {
        flex-direction: row-reverse;
    }
}

.download-data-modal__downloadButtonsContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-self: center;
}

.download-data-modal__downloadButton {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
}

.download-data-modal__error {
    color: $primary_red;
    font-size: 11px;
    margin-top: 4px;
}

.download-data-modal_datePickersContainer {
    display: flex;
    flex-direction: row;

    &--rtl {
        flex-direction: row-reverse;
    }
}

