$primary_red_color:#C84242;
$primary_yellow_color:#D9B64C;
$secondary_yellow_color:#F9FAD6;

$primary_black: #383C3A;
$secondary_black: #636766;
$tertiary_black: #898888;
$primary_grey: #DEDEDE;
$secondary_grey: #F1F1F1;
$tertiary_grey: #f3f3f3;
$primary_grey_bg: #FAFAFA;
$secondary_grey_bg: #FDFDFD;
$tertiary_grey_bg: #E9EEEE;
$primary_white: #FFFFFF;
$primary_blue: #546BD6;
$secondary_blue: #F0F3FF;
$tertiary_blue: #E2E7FA;
$primary_green: #0FB878;
$primary_green_hover: #00DC83;
$primary_green_bg: #d7f6ea;
$secondary_green: #EAF7F2;
$secondary_green_bg: #47b972;
$tertiary_green: #25D366;
$tertiary_green_bg: #EFF5F0;
$primary_red: #EB584E;
$secondary_red: #FFEEEE;
$primary_yellow: #E69700;
$secondary_yellow: #F9F5D5;
$tertiary_yellow: #FADB14;
$primary_grey_border: #EAEAEA;
$secondary_grey_border: #F0F0F0;
$primary_grey_hover: #EEEEF1;
$antd_border: #D9D9D9;
$antd_interactive_bg: #F5F5F5;

$link_color: #043db9;
$link_color_hover: #3a65c4;
