@import "src/styles/numbers";

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;

  @media (max-width: $mobile_sm_width) {
    padding: 16px;
  }
}

.buttons {
  width: 56%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 24px;

  @media (max-width: $mobile_sm_width) {
    width: 100%;
    gap: 16px;
  }
}