
@import "src/styles/colorVars";

.mobile-menu {
  background: var(--partner-theme-color, $primary_blue);
  width: 100vw;
  height: calc(100vh - 46px);
  display: grid;
  color: white;
  z-index:99;
  grid-template-rows: 175px max-content;
}

.mobile-menu__profile {
  opacity: 1;
  display: grid;
  place-items: center;
  place-content: center;
  height: 100%;
  width: 100vw;
  font-weight: 600;
  font-size: 18px;
  grid-template-rows: max-content max-content;
  grid-row-gap: 5px;
}

.mobile-menu__avatar {
  width: 70px;
  height: 70px;
}

.mobile-menu .MuiAvatar-img {
  width: 70px;
  height: 70px;
}

.mobile-menu .MuiAvatar-root {
  width: 70px;
  height: 70px;
}

.mobile-menu__buttons {
  display: grid;
  padding: 32px;
  width: 100vw;
}

.mobile-menu__button {
  display: grid;
  grid-template-columns: 40px 1fr;
  height: 40px;
  font-weight: 600;
  font-size: 18px;
  align-items: center;
  grid-column-gap: 16px;
  margin-bottom: 24px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: none;
    background: var(--partner-theme-color-light, #697ed6);
  }
}

.mobile-menu__exit {
  display: grid;
  align-self: end;
  align-items: center;
  height: 80px;
  border-top: 1px solid white;
  padding-left: 32px;
  margin-bottom: 42px;
  width: 100vw;
}

.menu-button_color {
  color: $primary_white;
}

@media (min-width: 1026px) {
  .mobile-menu {
    display: none;
  }
}
