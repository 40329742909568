@import "src/styles/colorVars";

.departmentColor {
    height: 30px;
    width: 30px;
}

.singleDepartmentInfo {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.singleDepartmentAgentsNumber {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 13px;
    line-height: 16px;
    color: $primary_black;
}

.teaserSlaTimeContainer {
    display: inline-flex;
    align-items: center;
    margin-left: 8px;
    padding-left: 8px;
    gap: 4px;
    border-left: 1px solid $primary_black;

    svg {
        width: 12px;
        height: 12px;
        
        path {
            fill: $primary_black;
        }
    }
}
