@import "src/styles/colorVars";

.csvSendMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  padding-top: 66px;
  position: relative;

  &.rtl {
    direction: rtl;
  }
}

.dottedLine {
  border-bottom: 1px dotted black;
  flex-grow: 1;
  height: 12px;
  margin: 0 6px;
}

.csvSendInfoMain {
  width: 100%;
  height: 100%;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.csvSendInfoBlock {
  display: flex;
  flex-direction: row;
  margin-bottom: 13px;
}

.csvInfoTitle {
  font-size: 14px;
  letter-spacing: -0.02em;
  color: $primary_black;
  margin: 0;
}

.csvInfoText {
  font-weight: bold;
  font-size: 14px;
  text-align: right;
  letter-spacing: -0.02em;
  color: $primary_black;
  margin: 0;
}

.reversed {
  direction: rtl;
}

.whatsappTerms {
  color: var(--primary-green, green);
  text-decoration: underline;
}

.whatsappTerms:hover {
  color: #019e5e;
}

.checkboxWrap {
  margin: 4px 0;
  :global {
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: var(--primary-color, var(--primary-green, green)) !important;
      border-color: var(--primary-color, var(--primary-green, green)) !important;
    }
  }
}

.scheduleDateForm {
  margin: 15px auto;
}

.extraInfoContainer {
  margin-top: 16px;
  width: 100%;
}

.unsubscribedIcon {
  margin-right: 8px;

  svg {
    color: $primary_red;
  }
}

.tooltipContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 8px;
  overflow-y: auto;
  max-height: 400px;
}

.tooltipRow {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
}

.tooltipAvatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}

.tooltipTitle {
    color: $primary_white;
    font-size: 12px;
    font-weight: bold;
}

.actions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  [class*="ant-row ant-form-item"] {
    margin: 0 0 16px;
  }
}