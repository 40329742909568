@import "src/styles/colorVars";

.map-marker {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: auto;
    right: 24px;
    bottom: 28px;
    color: $primary_red;
}
