@import "src/styles/colorVars";
@import "src/styles/numbers";

.container {
  background-color: rgba(253, 253, 253, 0.95);
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  margin-top: 24px;
  overflow: hidden;
}

.inner {
  height: 100%;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  color: $secondary_black;
  font-size: 36px;
  line-height: 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 0 16px;

  @media (max-width: 768px) {
    max-width: 100%;
  }
}

.actionBar {
  width: 100%;
  display: flex;
  background-color: $primary_grey;
}

.composeWrapper {
  width: 600px;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.compactIcon {
  svg {
    width: 20px;
    height: 20px;
    color: $primary_white;
  }

}

.innerTitle {
  font-size: 20px;
  color: $primary_black;
}

.innerSubtitle {
  font-size: 16px;
  text-transform: uppercase;
}

.placeholder {
  width: 24px;
  height: 24px;
}

.header {
  height: 58px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  gap: 8px;
  background-color: $primary_grey;
  overflow: hidden;
}

.closePreview {
  width: 24px;
  height: 24px;
  border-radius: 2px;
  text-align: center;
  line-height: 24px;
  transition: 500ms;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.nameWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  line-height: 16px;
  color: $primary_black;
  font-size: 14px;
}

.name {
  word-break: break-word;
}

.nameDetails {
  color: $secondary_black;
  font-size: 11px;
}

.innerContent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;

  &.compactVideo {
    pointer-events: none;
  }
}

.loadingIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 48px;
  height: 48px;
}

.pdfDocument {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pdfPage {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 4px 8px;
}

.noPreviewContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  padding: 16px;
}

.noPreviewContentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.compactContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 4px;
  text-transform: uppercase;
  justify-content: center;
  background-color: $primary_blue;
  font-weight: bold;
  height: 100%;
  width: 100%;
  
  span {
    color: $primary_white;
    font-size: 8px;
    line-height: 8px;
  }
}

.audioPlayer {
  border: 1px solid $primary_grey;
  border-radius: 28px;
  height: 54px;
  width: 100%;
}

.controlsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  width: 100%;
  min-width: 0;
  overflow-y: scroll;
  min-height: 126px;

  img, video {
    width: 100%;
    object-fit: cover;
  }
}

.previewBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 8px;
  padding: 8px;
  overflow: hidden;
  background-color: $tertiary_grey;
  border-radius: 4px;
  white-space: nowrap;
  height: 100%;
  width: 100%;

  &.selected {
    background-color: transparent;
  }
}

.previewText {
  display: flex;
  flex-direction: column;
  gap: 0;
}

.previewIcon {
  width: 24px;
  height: 24px;
}

.previewTitle {
  font-size: 12px;
  font-weight: normal;
  letter-spacing: -0.5px;
}

.previewSize {
  font-size: 11px;
  line-height: 11px;
  color: $secondary_black;
  letter-spacing: -0.5px;
}

.controlsRemove {
  display: flex;
  justify-items: center;
  align-items: center;
  position: absolute;
  bottom: -12px;
  cursor: pointer;

  &:hover {
    svg {
      fill: $primary_red;
    }

    path {
      stroke: $primary_white;
    }
  }

}

.controlsBoxPreview {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 112px;
  min-width: 98px;
  height: 98px;
  position: relative;
  border-radius: 4px;
  border: 1px solid transparent;
  cursor: pointer;

  &.selected {
    cursor: default;
    border-color: var(--primary-color);
  }

  &:hover {
    border-color: var(--primary-color);
  }

  &.mainPreview {
    height: auto;
    width: auto;
    cursor: default;

    .previewBox {
      background-color: transparent;
      gap: 8px;
    }
    .previewTitle {
      font-weight: bold;
      font-size: 16px;
      color: $primary_black;
    }
    .previewSize {
      font-size: 14px;
    }
    .previewIcon {
      height: 48px;
      width: 48px;

      svg {
        height: 48px;
        width: 48px;
      }
    }
    .previewText {
      gap: 8px;
    }
  }
}

.controlsAdd {
  width: 98px !important;
  min-width: 98px;
  height: 98px !important;
  border-width: 1px !important;
  border-radius: 4px;
  gap: 8px;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg {
    width: 24px;
    height: 24px;

    path {
      fill: var(--primary-color);
    }
  }
  
  &:hover {
    svg, path {
      fill: var(--primary-color);
    }
  }

}

.deleteIcon {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  justify-content: center;
  
  svg {
    width: 24px;
    height: 24px;
    fill: $primary_grey;
  }
}