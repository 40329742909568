.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 12px 0 12px 0;
}

.closeFieldButtonCircle {
  height: 31px;
  width: 31px;
  border: 1px solid #EAEAEA !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.closeFieldButtonCircle:hover {
  border: 1px solid #EAEAEA !important;
}

.open {
  transform: rotate(0deg);
  transition: transform 0.5s linear;
  filter: invert(18%) sepia(77%) saturate(2822%) hue-rotate(347deg) brightness(118%) contrast(94%);
}

.closed {
  transform: rotate(45deg);
  transition: transform 0.5s linear;
  filter: invert(60%) sepia(98%) saturate(476%) hue-rotate(92deg) brightness(93%) contrast(78%);
}
