@import "src/styles/colorVars";
@import "src/styles/numbers";

.formWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.submitWrapper {
    margin-top: 32px;
}

.submitButton {
  width: 388px;
  min-width: 388px;
  height: 46px !important;
  cursor: pointer;
  background: var(--primary-color, var(--primary-green, green));
  border-radius: 2px;
  border: var(--primary-color, var(--primary-green, green));

  font-family: SF UI Text, 'Segoe UI';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.02em;
  color: $primary_white;

  &:hover {
      background: #00ab66;
      color: $primary_white !important;
  }

  &:focus {
      outline: none;
  }

  &.disabled {
      background: #cccccc;
      cursor: auto;

      &:hover {
          background: #cccccc;
      }
  }

  @media (max-width: $mobile_sm_width) {
      width: calc(100vw - 24px);
      min-width: calc(100vw - 24px);
  }
}