@import "src/styles/colorVars.scss";

.previewContainer {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
  min-height: 280px;
}

.preview {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $secondary_green;
  background-blend-mode: hard-light;

  &.bulkSend {
    justify-content: flex-start;
  }
}

.messageBlock {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 16px 0;
}

.message {
  min-width: 90%;
  max-width: 90%;
}

.label {
  display: flex;
  margin: 20px 0 5px;
  justify-content: space-between;
  text-transform: capitalize;
  font-style: normal;
  letter-spacing: -0.02em;
  color: rgba(56, 60, 58, 0.7);
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #383C3A;
}

.innerBlock {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 34px;
  background-color: $primary_white;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12);
  transition: max-height 0.3s ease-in-out;

  &.collapsable {
    max-height: 280px;
    overflow: hidden;
  }

  &.collapsed {
    &:after {
      position: absolute;
      bottom: 0;  
      height: 100%;
      width: 100%;
      content: "";
      background: linear-gradient(to top,
        rgba(255,255,255, 1) 15%, 
        rgba(255,255,255, 0) 85%
      );
      pointer-events: none;
    }
  }

  &.extended {
    max-height: 100%;
  }
}

.collapsableButton {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 32px;
  padding: 8px 0;
  border-top: 1px solid $secondary_grey;
  z-index: 2;
  cursor: pointer;
  color: var(--link-color);

  &:hover {
    background-color: $primary_grey_bg;
  }
}

.innerTopBlock {
  padding: 10px;
}

.image {
  width: 100%;
  max-width: 100%;
  height: auto;
  max-height: 180px;
  object-fit: cover;
}

.video {
  width: 100%;
  max-width: 100%;
  height: 148px;
  object-fit: cover;
}

.text {
  color: black !important;
  white-space: pre-wrap;
  word-break: break-word;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
}

.footerText {
  color: $tertiary_black !important;
  opacity: 0.5;
  white-space: pre-wrap;
}

.actionButtons {
  display: flex;
  flex-direction: column;
  border-top: 1px solid $secondary_grey;
}

.actionButton {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $primary_white;
  padding: 10px;
  .actionIcon svg{
    width: 12px;
    height: 12px;
  }
  .actionIcon svg path{
    fill: $primary_blue;
  }
  .actionText {
    color: $primary_blue !important;
    margin-left: 4px;
  }
}

.quickButtons {
  display: flex;
  flex-direction: row;
  margin-top: 4px;
  flex-wrap: wrap;
  justify-content: space-between;
  .nonSingleButton {
    width: 49%;
    min-width: 49%;
  }
}

.quickButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $primary_white;
  border-radius: 4px;
  padding: 10px 6px;
  width: 100%;
  min-width: 50%;
  word-break:break-all;
  margin-bottom: 4px;
  color: $primary_white;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12);
}

.rtl {
  direction: rtl;
  text-align: right;
}

.highlightMissing {
  color: $primary_red;
}

.highlightSuccess {
  color: $primary_green;
}