@import "src/styles/colorVars.scss";

.wrapper{
    width: 32px;
    min-width: 32px;
    height: 32px;
    border-radius: 2px;
    text-align: center;
    line-height: 32px;
    transition: 500ms;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.wrapper:hover{
    background: #F5F5F5;
}

.alert:hover {
    background: $secondary_red;
}

.disabled {
    background: #F5F5F5;
    cursor: initial;
}