@import "src/styles/colorVars";

.attachFileFilesDropzone {
    width: 145px;
    height: 48px;
}

.attachFileLoaded {
    width: 100%;
}

.advancedSettingsTextblock {
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    display: flex;
    flex-direction: row;
}

.advancedSettingsTextblockRequired {
    border-color: #EE6363;
}

.advancedTextboxActive {
    cursor: pointer;
    border-color: var(--primary-green);
}

.label {
    display: flex;
    margin: 20px 0 5px;
    justify-content: space-between;

    font-family: SF UI Text;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: rgba(56, 60, 58, 0.7);
}

.label:first-child {
    text-transform: capitalize;
}

.templateEmojiPickerTrigger {
    margin: 20px 30px 36px 5px;
}

.templateEmojiPickerTrigger svg {
    height: 18px;
    width: 18px;
}

.templateEmojiPicker {
    position: absolute;
    right: 100px;
    bottom: -90px;
    z-index: 2;
}

.attachFileToReplyButton {
    display: flex !important;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
    width: 145px;
    height: 48px !important;
    margin-top: 12px;
    transition: none !important;

    &:hover {
        color: $primary_green !important;
    }
}

.attachFileIcon {
    margin-right: 12px;
}

.attachFileChosen {
    justify-content: space-between;
    gap: 8px;
    width: 100%;
    background-color: #EAEAEA;
}

.deleteCsvFile {
    cursor: pointer;
}

.button-help-text {
    display: flex;
    align-items: center;
    margin-top: 12px;
    gap: 8px;
  }
  
  .info-text {
    font-family: SF UI Text;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: -0.02em;
    margin: 0;
  
    color: $tertiary_black;
  }
  
  .info-circle {
    svg {
      fill: $tertiary_black;
      width: 16px;
      height: 16px;
    }
  }