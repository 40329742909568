@import "src/styles/colorVars";

.container {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
  overflow-y: auto;
  padding: 25px;
}

.title {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 16px;
}

.channelSelect {
  li {
    cursor: pointer;
    border-left: 2px solid transparent;

    &:hover,
    &[class*="selected"] {
      background-color: $primary_grey_hover;
      border-left-color: $primary_green;
    }
  }

  &.rtl {
    li {
      border-left: none;
      border-right: 2px solid transparent;
  
      &:hover {
        border-right-color: $primary_green;
      }
    }
  }
}