@import "src/styles/colorVars";

.title {
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.titleText {
    font-size: 20px;
}

.closeModal {
    display: flex;
    align-items: center;
}

.titleWrapper {
    padding: 20px 25px;
    border-bottom: 2px solid $primary_grey_border;
    background-color: $primary_white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 84px;
    min-height: 84px;
    position: sticky;
    top: 0;
    z-index: 2;

    &::after {
        content: '';
        border-bottom: 2px solid $primary_green;
        width: 56px;
        left: 25px;
        position: absolute;
        display: block;
        top: 82px;
    }
}
