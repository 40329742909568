@import 'src/styles/numbers';
@import 'src/styles/colorVars';

.modalWrapper {
  z-index: 1501 !important;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 8px;

    &.rtl {
        direction: rtl;
    }
}

.title {
    font-size: 16px;

    @media (max-width: $mobile_sm_width) {
        font-size: 14px;
    }
}

.subtitle {
    font-size: 14px;
    color: $secondary_black;
    margin-top: 8px;
}

.icon {
    svg {
        width: 24px;
        height: 24px;
        fill: $primary_yellow;
        margin-bottom: 16px;
    }
}

.buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    gap: 16px;
    margin-top: 24px;

    @media (max-width: $mobile_sm_width) {
        gap: 16px;
    }
}
