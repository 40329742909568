@import "src/styles/numbers";

.right-bar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: $rightModuleBar_width;
}

.right-bar .right-bar-contents {
  height: calc(100% - 196px);
}

.mobile-drawer {
  height: 100%;
}

@media (max-width: 1026px) {
  .right-bar {
    display: none;
  }
}
