@import "src/styles/colorVars";

.upload-csv-page-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-y: auto;
    padding-top: 66px;
    position: relative;

    &.rtl {
        direction: rtl;
    }
}

.csv-example {
    color: var(--primary-green);
    text-decoration: underline;
    
    &:hover {
        color: #019e5e;
    }
}

.csv-wizard-warning-block {
    display: flex;
    flex-direction: row;
    gap: 4px;
    padding: 0 32px;
    margin-top: 16px;
}

.file-upload-block {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.wizard-text {
    font-family: SF UI Text;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: -0.02em;
    margin: 0;

    color: $tertiary_black;
}

.wizard-info-circle {
    margin-top: 2px;
    svg {
        fill: $tertiary_black;
    };
}