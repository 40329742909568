.infectedModalContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 40vh;
}

.bugIcon svg {
    height: 40px;
    width: 40px;
    margin-bottom: 22px;
}

.warningText {
    font-family: SF UI Text,"Segoe UI";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #DB3B3B;
}

.warningMainText {
    font-family: SF UI Text,"Segoe UI";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #000000;
    margin: 24px 0 48px 0;
}

.buttonsBlock {
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.infectedModalButton {
    width: 164px
}

.infectedModalButtonWhite {
    border: 1px solid #EAEAEA !important;
}

.infectedModalButtonRed {
    background-color: #DB3B3B !important;
}

.infectedModalButtonText {
    text-transform: capitalize;
}

.infectedModalButtonTextWhite {
    color: white !important;
}
