@import "src/styles/colorVars";

h1 {
    font-weight: 600;
    font-size: 16px;
    color: $primary_black;
}

p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.15px;
}

.user-item__active {
    background: linear-gradient(0deg, #D7F7EA, #D7F7EA), #F5F5F5;
    border-radius: 2px;
}

.user-item__img-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-item__img {
    height: 18px;
    width: 18px;
    justify-self: center;
    color: var(--primary-green);
}

.user-item__img svg {
    height: 18px;
    width: 18px;
}

.user-item__department {
    display: flex;
    align-items: center;
    gap: 12px;
}

.conversation-list-item {
    display: flex;
    padding: 15px 0 13px 10px;
    min-width: 375px;
}

.conversation-list-item:hover {
    background: #eeeef1;
    cursor: pointer;
}

.conversation-photo {
    min-width: 50px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
}

.conversation-info {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 15px;
}

.conversation-info > div:last-child {
    min-width: 64px;
}

.conversation-info.unread, .conversation-info.unread .conversation-title {
    font-weight: bold;
}

.conversation-title-wrapper {
    width: 100%;
    margin: 3px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.conversation-title {
    font-size: 14px;
    margin: 0;

    font-family: SF Pro Text,"Segoe UI";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    /* identical to box height, or 131% */
    letter-spacing: -0.33px;
}

.conversation-message {
    font-size: 14px;
    color: $tertiary_black;
    margin: 0;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height */
    letter-spacing: -0.15px;
}
