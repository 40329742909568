.template-message-container {
    display: flex;
    flex-direction: column;
    width: 100%;

    &.rtl {
        direction: rtl;

        .template-message-drawer-title-icon {
            transform: rotate(180deg);
        }
    }
}

.template-message-drawer-title-icon {
    svg {
        font-size: 1.25rem;
    }
}

.template-message-drawer-title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    font-weight: 500;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    flex: 0 1;
    font-size: 16px;
    line-height: 22px;
    padding: 16px 24px;
}

.template-message-button {
    background-color: white;
    border: transparent;
    width: 100%;
    outline: none;
    cursor: pointer;
    height: 54px;
    padding: 0 24px;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    & > div {
        display: flex;
        align-items: center;
        gap: 16px;
    }
}

.template-message-drawer-icon {
    color: white !important;
}

.template-message-button:hover {
    background-color: lightgrey;
}

.ant-drawer-header {
    border-bottom: none !important;
}

.template-message-drawer-divider {
    margin: 0 !important;
}

.template-message__limitContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 16px;
    padding: 0 32px;
    margin: 24px 0;
}

.template-message__limitRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.template-message__text {
    font-size: 11px;
    letter-spacing: -0.02em;
    text-transform: capitalize;
}

.template-message__progressBar {
    max-width: 80%;

    [class='ant-progress-outer'] {
        padding-right: calc(2em + 12px) !important;
    }
}

.template-message__infoIcon {
    svg {
        width: 16px;
        height: 16px;
    }
}

.template-message__loadingIcon {
    margin-right: 4px;

    svg {
        width: 12px;
        height: 12px;
    }
}

.limit-dashboard__tooltip {
    z-index: 1500 !important;
}