@import "src/styles/numbers";

.contactList {
    display: flex;
    flex-direction: column;
    padding: 0 32px;
    margin-top: 16px;
    overflow-y: auto;

    @media (max-width: $mobile_width) {
        padding: 0 16px;
    }
}

.contact-item-chosen-check {
    width: 10px;
    justify-self: flex-end;
}

.noContactsFound {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 14px;
}