@import "src/styles/colorVars";
@import "src/styles/numbers";

.button {
  height: 48px !important;
  min-width: 168px;
  text-transform: capitalize;

  [class="ant-btn-loading-icon"] {
    margin-right: 8px;
  }

  @media (max-width: $mobile_sm_width) {
    min-width: 0;
    width: 100%;
  }
}

.white:hover {
  border-color: $primary_green !important;
  color: $primary_green !important;
}

.green {
  background-color:$primary_green !important;
  border: 1px solid $primary_green !important;
  color: $primary_white !important;
}

.green:hover {
  background-color: $primary_green_hover;
  filter: brightness(85%);
  border-color: $primary_green_hover !important;
}

.red {
  background-color:$primary_red !important;
  border: 1px solid $primary_red !important;
  color: $primary_white !important;
}

.red:hover {
  border: 1px solid $primary_red !important;
  filter: brightness(85%);
  background-color:$primary_red !important;
}

.disabled {
  color: rgba(0, 0, 0, 0.25) !important;
  background: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
}

.disabled:hover {
  filter: brightness(100%);
  border-color: #d9d9d9 !important;
}
