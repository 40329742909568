@import 'src/styles/colorVars';
@import 'src/styles/numbers';

.wizardHeader {
    height: 80px;
    min-height: 80px;
    width: 100%;
    border-bottom: 2px solid #dedede;
    padding: 0 24px;
    letter-spacing: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    word-wrap: break-word;
    position: relative;
    margin-top: 0;
    margin-bottom: 0;
    color: #353535;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.green::after {
    content: '';
    border-bottom: 2px solid #30c498;
    width: 56px;
    position: absolute;
    display: block;
    top: 78px;
}

.red::after {
    content: '';
    border-bottom: 2px solid #e26565;
    width: 56px;
    position: absolute;
    display: block;
    top: 78px;
}

.wizardTitle {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 4px;
}

.titleLimit {
    text-transform: capitalize;
    color: $secondary_black;
    font-size: 13px;
    font-weight: 400;
}

.wizardTitleIcon {
    margin: 0 10px 0 0;
}

.closeWizardWrapper {
    width: 32px;
    height: 32px;
    border-radius: 2px;
    text-align: center;
    line-height: 32px;
    transition: 500ms;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.closeWizardWrapper:hover {
    background: #f5f5f5;
}

.channelSelectorWrapper {
    display: flex;
    align-items: center;
    gap: 25px;
}

.channelSelector {
    [class="ant-select-selector"] {
        width: 300px !important;
    }

    @media (max-width: $mobile_sm_width) {
        [class="ant-select-selector"] {
            width: 150px !important;
        }
    }
}
