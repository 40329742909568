.department-item h1 {
    font-weight: 600;
    font-size: 16px;
    color: #000000;
}

.department-item p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.15px;
    color: #666666;
    opacity: 0.6;
}

.department-item__active {
    background: linear-gradient(0deg, #D7F7EA, #D7F7EA), #F5F5F5;
    border-radius: 2px;
}

.department-item__avatar {
    border-radius: 2px;
}

.department-item__img-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.department-item__img {
    height: 17px;
    width: 17px;
    justify-self: center;
    color: var(--primary-green);
}
