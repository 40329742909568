@import "src/styles/numbers";
@import "src/styles/colorVars";

.blockerContainer {
    background-color:#FAFAFA;
    position: absolute;
    top:0;
    left:0;
    height: 100%;
    width: 100%;
    z-index: 1;

    border-left: 1px solid #EAEAEA;
    border-right: 1px solid #EAEAEA;

    display: flex;
    justify-content: center;
    align-items: center;
}

.blockerInnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.blockerImage {
    svg {
        height: 60px;
        width: 60px;
        fill: $tertiary_black;
    }
}

.blockerTitle {
    margin-top: 25px;
    margin-bottom: 0;
    font-family: SF UI Text,"Segoe UI";
    font-size: 16px;
    line-height: 24px;
    color: #383C3A;
}

@media (max-width: $mobile_width) {
    .blockerContainer {
        display: none;
    }
}
