@import "src/styles/colorVars";

.addDepartmentLabeledInput {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-bottom: 20px;
}

.addDepartmentError {
    font-family: SF UI Text,"Segoe UI";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: rgba(218, 63, 63, 0.96);
    margin-top: 3px;
}

.addDepartmentAgentsList {
    height: 300px;
    overflow-y: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 8px;

    &.disabled {
        pointer-events: none;
        opacity: 0.5;
    }
}

.addDepartmentAgent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    width: 100%;
    padding: 8px 12px;
    border-bottom: 1px solid $primary_grey_border;
}

.selectAgentContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.addDepartmentSelect {
    margin: 16px 0 8px 0 !important;


}

.addDepartmentRoles {
    display: flex;
    align-items: center;
}

.unassignAgentButton {
    height: 16px;
    width: 16px;
    cursor: pointer;
  }

.extraInfo {
    font-size: 12px;
    color: $tertiary_black;
    display: inline-block;
    margin-left: 8px;
}

.slaContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 4px;
    margin-top: 4px;
}

.slaTimePicker {
    min-width: 150px;
}

.slaSwitch {
    &[class*="ant-switch-checked"] {
        background: $primary_green !important;
    }
}