@import 'src/styles/colorVars';
@import 'src/styles/numbers';

.content {
    width: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
    align-items: center;
}

.header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-bottom: 1px solid $primary_grey_border;
    padding: 24px;

    h3 {
        font-size: 20px;
        font-weight: 600;
    }

    .createButton {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
        transition: none;

        &:hover,
        &:focus {
            color: var(--primary-green) !important;

            path {
                fill: var(--primary-green);
            }
        }
    }

    &.hidden {
        display: none;
    }
}

.footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    border-top: 1px solid $primary_grey_border;
    padding: 16px 24px;
    gap: 12px;
}

.table {
    width: 100%;
    height: 100%;
    overflow-y: auto;

    td,
    th {
        padding: 4px 8px !important;
    }
}

.formWrapper {
    display: grid;
    grid-template-rows: 1fr auto;
    height: 100%;
    padding-top: 24px;
    overflow: hidden;

    [class*='ant-radio-group'] {
        margin-left: 24px;
        width: 100%;
    }
}

.form {
    overflow-y: auto;
}

.formItem {
    padding: 0 24px !important;
}

.rangePicker {
    margin: 24px 0 0 24px !important;
}

.actionsWrapper {
    display: flex;
    align-items: center;
    gap: 16px;
}

.button:not([disabled]) {
    &:hover {
        color: $primary_green !important;
    }
    &[class*='ant-btn-primary']:hover {
        color: $primary_white !important;
    }
}

.loading {
    font-size: 24px;

    svg {
        color: $primary_green;
        fill: $primary_green;
    }
}

.downloadButton {
    display: flex !important;
    align-items: center;
    gap: 4px;

    &:hover {
        color: $primary_white !important;
        filter: brightness(0.95);
    }
}

.alert {
    width: 100%;
    padding-inline: 32px;
    margin-bottom: 24px !important;

    &--rtl {
        direction: rtl;
        text-align: right;
        gap: 10px;
    }
}

.alertDescription {
    display: flex;
    align-items: center;
    gap: 8px;
}

.scheduleTooltip {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding: 4px;
}

.scheduleWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
}

.scheduledPicker {
    min-width: 160px;
    width: 160px;
}
