$mobile_sm_width: 768px;
$mobile_width: 1026px;
$desktop_width: 1600px;

$sidebar_width: 64px;
$header_height: 72px;
$compose_height: 66px;

$chatsList_width: 380px;
$rightModuleBar_width: 320px;
