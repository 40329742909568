.item {
    border-left: transparent 2px solid;
    padding: 32px 32px 31px 40px;
    cursor: pointer;
    &:hover {
        background: #F5F5F5;
        border-left: #21AE84 2px solid;
    }
} 

.itemText {
    direction: rtl;
    text-align: right;
    font-size: 14px;
    overflow-wrap: break-word;
    white-space: pre-wrap;
}

.replyDepartmentsWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 12px;
    width: 100%;
    overflow: auto;
    direction: rtl;
  }
  