@import 'src/styles/colorVars';
@import 'src/styles/numbers';

.container {
    width: 100%;
    min-height: 45px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    gap: 8px;
    max-width: 400px;
    margin-right: 0;
    color: $primary_black;
}

.documentsWrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.sectionTitle {
    opacity: 0.7;
    font-size: 12px;
    color: $primary_white;
}

.content {
    background-color: rgba(243, 243, 243, 0.1);
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    min-height: 45px;
    cursor: pointer;
}

.icon {
    height: 30px;
    width: 30px;
    margin: 0 10px;

    svg {
        height: 28px;
        width: 28px;
    }
}

.incomingBackground {
    background-color: #e8e8e8 !important;
}

.incomingText {
    color: $primary_black !important;
}

.caption {
    align-self: flex-start;
    line-height: 16px;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    max-width: 300px;
    color: $primary_white;

    &.incoming {
        color: $primary_black;
    }
}

.text {
    min-width: 130px;
    max-width: 300px;
    font-size: 14px;
    color: #e8e8e8;
    margin-bottom: 0;
}

.documentsGridWrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.mediaGridWrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 4px;
    margin-top: 4px;

    [class='image-message-container'] [class='ant-image'] {
        width: 100px;
        max-width: 100px;
        height: auto;
    }
}

.videoImageWrapper {
    margin: 0;
}
