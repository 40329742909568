@import "src/styles/colorVars";
@import "src/styles/numbers";

.listItem {
  display: flex;
  padding: 16px 12px;
  min-width: 375px;
  max-width: 450px;
  width: 100%;
  gap: 12px;

  @media (min-width: $mobile_width) {
    min-width: 300px;
  }
}

.listItem:hover {
  background: #eeeef1;
  cursor: pointer;
}
.listItemActive {
  background: #eeeef1;
}

.photo {
  min-width: 50px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.message {
  font-size: 14px;
  color: $tertiary_black;
  margin: 0;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  letter-spacing: -0.15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.showUnreadCount {
    font-weight: bold;
    color: $primary_black;
  }
}

.mediaLastMessage {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  svg {
    width: 16px;
    height: 16px;
  }
}

.titleWrapper {
  width: 100%;
  margin: 3px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.titleInnerWrapper {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 12px;
  align-items: center;
  min-width: 0;
}

.messageBlock {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 12px;
  align-items: center;
  margin-top: 4px;
  margin-bottom: 4px;
  min-width: 0;
}

.title {
  font-size: 14px;
  margin: 0;

  font-family: SF Pro Text,"Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height, or 131% */
  letter-spacing: -0.33px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.assignedInfo {
  margin: 0;
  text-align: right;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.info > div:last-child {
  min-width: 112px;
}

.info.unread, .info.unread .title {
  font-weight: bold;
}

.status {
  color: #0FB878;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 18px;
  text-transform: uppercase;
}

.divider {
  margin: 0 !important;
}

.unreadMessages {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 14px;
  width: 14px;
  min-width: 14px;
  background-color: var(--primary-green);
}

.unreadMessagesNumber {
  margin-top: 9px;
  font-family: SF Pro Text;
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  line-height: 14px;
  text-align: center;
  color: white;
}

.photoWrapper {
  position: relative;
}

.photoSmallLogo {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 34px;
  right: 1px;
}

.lastMessageDate {
  display: flex;
  justify-content: flex-end;
  font-weight: normal;
  font-size: 12px;
  color: $tertiary_black;
  white-space: nowrap;
}

.lastMessageInfo {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  justify-self: end;
}

.titleImportanceWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  gap: 4px;
}

.errorIcon {
  svg {
    width: 20px;
    height: 20px;
  }
}

.statusIcon {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.statusIcon svg {
  width: 12px;
  height: 12px;
}

.menuIcon {
  width: 16px;
  height: 16px;

  svg {
    width: 14px;
    height: 14px;
  }
}

.errorIcon svg path{
  fill:$primary_red
}

.skeleton {
  padding: 15px 15px 12px 10px !important;
  :global {
    .ant-skeleton-title {
      margin-top: 8px !important;
    }
    .ant-skeleton-title + .ant-skeleton-paragraph {
      margin-top: 13px !important;
    }
  }
}

@media (max-width: 1024px) {
  .listItem {
    min-width: 0;
    max-width: 100vw;
  }
}

.unsubscribedIcon {
  margin-right: 8px;

  svg {
    width: 16px;
    height: 16px;
    color: $primary_red;
  }
}
