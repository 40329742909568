@import 'src/styles/colorVars';

.messageContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 4px;
}

.messageType {
    color: $secondary_black;
    font-size: 12px;

    &.outgoing {
        color: $primary_white;
    }
}
