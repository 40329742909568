@import 'src/styles/colorVars';

.main {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 6px;
    padding: 5px;
    padding-right: 12px;
    min-width: 0;

    &.reply {
        height: 100%;
        gap: 4px;

        p:first-child {
            font-weight: bold;
        }
    }
}

.name {
    margin: 0;
    color: $primary_green;

    &.incoming {
        color: $primary_blue;
    }

    &.invertColors {
        color: $secondary_black;
    }
}

.text {
    overflow: hidden;
    text-overflow: ellipsis;
    color: $primary_black;
    width: 100%;
    margin: 0;
    line-height: 16px;
    display: flex;
    align-items: center;
    max-height: 60px;
    line-clamp: 3;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;

    &.invertColors {
        color: $primary_white;
    }

    &.reply {
        line-clamp: 2;
        -webkit-line-clamp: 2;
        line-height: 14px;
    }
}
