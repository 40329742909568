.uploadButton {
  display: flex;
  width: fit-content;

  &.fluid {
    width: 100%;

    & > div {
      width: 100%;
    }
  }
}

.buttonText {
  display: flex;
  align-items: center;
  justify-content: center;
}