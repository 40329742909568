.singleChatNote {
    display: flex;
    justify-content: space-between;
    min-height: 68px;
}

.chatNoteAvatar {
    border-radius: 50%;
    border: none;
    height: 32px;
    width: 32px;
    z-index: 1
}

.chatNoteInfoBlock {
    padding-bottom: 12px;
    padding-top: 6px;

    min-height: 68px;
    width: 200px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    border-bottom: 1px solid #EAEAEA;
}

.chatNoteInfoBlock::before {
    content: '';
    border-left: 1px solid #EAEAEA;
    height: 62px;

    margin-top: 32px;
    left: 16px;

    position: absolute;
}

.chatNoteName {
    font-family: SF UI Text;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #4C5250;
    opacity: 0.7;
}

.chatNoteText {
    font-family: SF UI Text;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.02em;

    overflow-wrap: break-word;

    color: #383C3A;
}

.chatNoteDate {
    font-family: SF UI Text;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: -0.02em;

    color: #4C5250;

    opacity: 0.7;
}

.chatNoteActions {
    display: flex;
    align-items: center;

    span {
        cursor: pointer;
    }
}


