@import "src/styles/colorVars";
@import "src/styles/numbers";

.contact {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 14px 40px;
  overflow-y: auto;
  gap: 16px;

  @media (max-width: $mobile_width) {
    flex-direction: column;
    padding: 14px 12px;
  }
}

.info {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.divider {
  margin: 0 !important;
}

.buttonText:not(.disabled) {
  color: $primary_white !important;
}

.nameWrapper {
  display: flex;
  align-items: center;
}

.avatar {
  width: 41px;
  height: 41px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 16px;
}

.name {
  font-size: 16px;
  font-weight: 600;
  color: $primary_black;
}

.email {
  font-size: 14px;
  font-weight: 400;
  color: $secondary_black;
}

.phoneSelector {
  [class="ant-select-selector"] {
    min-width: 200px;
  }
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}