@import "src/styles/colorVars.scss";

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.icon {
    margin-bottom: 26px;
}

.icon svg{
    width: 26px;
    height: 26px;
    fill: $primary_yellow;
}

.title {
    font-family: SF UI Text;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #383C3A !important;

    margin-bottom: 24px;
}

.subtitle {
    font-family: SF UI Text;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.02em;

    margin-bottom: 48px;
}
