@import 'src/styles/colorVars';
@import 'src/styles/numbers';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    position: relative;
}

.modalContainer {
    width: 100% !important;
    height: 100%;
    max-width: 100vw !important;
    padding: 0 !important;
    top: 0 !important;

    [class='ant-modal-content'] {
        background-color: transparent !important;
    }
    [class='ant-modal-body'] {
        padding: 0 !important;
    }
}

.centralMessageContainer {
    position: absolute;
    top: calc(50% + 48px);
    left: calc(50% + 48px);
    transform: translate(-50%, -50%);
    width: 720px;
    color: white;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 16px;

    &.rtl {
        .messageContent {
            direction: rtl;
        }
    }

    &.right {
        .arrowIcon {
            transform: rotate(30deg);
        }
    }

    &.rtl.right {
        direction: rtl;
    }

    &.right:not(&.rtl) {
        flex-direction: row-reverse;
    }

    @media (max-width: $mobile_width) {
        width: calc(100% - 32px);
        flex-direction: column;
        align-items: center;
        top: calc(50% + 80px);
        left: 50%;
    }
}

.messageContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;

    @media (max-width: $mobile_width) {
        align-items: center;
        text-align: center;
    }
}

.title {
    font-size: 36px;
    text-transform: capitalize;
    line-height: 42px;
    font-weight: 300;

    @media (max-width: $mobile_width) {
        font-size: 24px;
        line-height: 32px;
    }
}

.subtitle {
    font-size: 24px;
    line-height: 32px;
    text-transform: capitalize;
    font-weight: 400;
    max-width: 520px;

    @media (max-width: $mobile_width) {
        font-size: 18px;
        line-height: 24px;
    }
}

.arrowIcon {
    min-width: 86px;
    width: 86px;
    height: 86px;
    fill: white;
    background-color: $primary_blue;
    border-radius: 50%;
    transform: rotate(-30deg);
    padding: 12px;

    @media (max-width: $mobile_width) {
        min-width: 64px;
        width: 64px;
        height: 64px;
        padding: 8px;
    }
}

.button {
    min-width: 168px;
    height: 48px !important;
    text-transform: uppercase;
}

.exampleNotificationImageContainer {
    position: absolute;
    top: 0;
    left: 120px;

    &.rtl {
        left: initial;
        right: 120px;
    }

    @media (max-width: $mobile_width) {
        left: 0;
    }
}

.imageExample {
    width: 250px;
    height: auto;
    object-fit: cover;
    border-radius: 4px;
    margin-top: 16px;

    @media (max-width: $mobile_width) {
        width: 250px;
    }
}

.lockIcon {
    width: 24px;
    height: 24px;
    position: relative;
    bottom: 2px;
}
