.formControl {
    margin: 8px !important;
    width: 80px !important;

    [class*='MuiInput-formControl'] {
        margin-top: 0;
        font-size: 14px;
    }
}

.selectEmpty {
    margin-top: 16px;
}

.paper {
    li {
        display: flex;
        align-items: center;
        gap: 4px;
    }
}