@import "src/styles/numbers";
@import "src/styles/colorVars";

.leftSidebar {
  width: $sidebar_width;
  min-width: $sidebar_width;
  background: var(--partner-theme-color, $primary_blue);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 18px 0;
}

.leftSidebarMenu {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.leftSidebarMenuTop {
  align-items: center;
  flex-direction: column;
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: var(--partner-theme-color-dark, #637EF5);
  height: 72px;
}

.leftSidebarIcon {
  margin-bottom: 0;
  border-radius: 8px;
  max-width: 42px;
  max-height: 42px;
}

@media(max-width: $mobile_width) {
  .leftSidebar {
    display: none;
  }
}
