.deleted-message-container {
    display: inline-flex;
    flex-direction: row;
    width: 100%;
    margin-right: -42px;
    margin-bottom: -10px;
}

.deleted-sign {
    width: 14px;
    height: 14px;
    margin-top: 1px;
    margin-right: 5px;
    color: #A6A6A6;
}

.deleted-message-text {
    margin-top: 0;
    height: 16px;
    width: 180px;

    font-family: SF UI Text,"Segoe UI";
    font-style: italic;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #A6A6A6;
}
