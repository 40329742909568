.context-menu-trigger-wrapper {
    height: 24px;
    width: 24px;
    position: absolute;
    top: 5px;
    right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 15px 15px 50px 15px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
}

.context-menu-incoming {
    background: linear-gradient(270deg, rgba(84, 107, 214, 0) 0%, #ffffff 100%);
}

.context-menu-overlay-wrapper {
    .ant-dropdown-menu-title-content {
        white-space: nowrap;
    }
}

.with-background {
    background: var(--background-color);
}

.context-menu-trigger-incoming {
    color: black !important;
}
.context-menu-trigger-outgoing {
    color: red !important;
}
