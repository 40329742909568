.filter-departments-dropdown-select {
    width: 100%;
}

.ant-select-selector {
    max-width: 100%;
    box-sizing: border-box !important;
}

.ant-select-selection-item {
    padding: 0;
}

.filter-departments-option-text {
    margin: 0;
}

.filter-departments-option-color {
    content: '';
    min-width: 8px;
    height: 8px;
    display: block;
    float: left;
    margin-right: 8px;
}
