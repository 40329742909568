@import 'src/styles/colorVars';

.myinput-wrapper {
    padding: 8px;
    border-radius: 8px;
    border-width: 2px !important;
    outline: none;

    [class="ant-input-prefix"] {
        margin-right: 8px;
    }

    &.rtl {
        direction: rtl;

        [class="ant-input-prefix"] {
            margin-right: 0;
            margin-left: 8px;
            transform: scaleX(-1);
        }
    }

    &.full-width {
        width: 100%;
    }

    &.large {
        height: 48px;
        font-size: 14px;
    }
    &.middle {
        height: 40px;
    }

    .ant-input-clear-icon {
        display: flex;
        align-items: center;
    }

    &:hover,
    &:focus,
    &:active,
    &:visited,
    &:focus-within
    {
        border-color: var(--primary-color) !important;
        box-shadow: none;

        span[class='ant-input-prefix'] svg {
            fill: var(--primary-color) !important;
        }
    }
}

.myinput-loading,
.myinput-search {
    font-size: 20px;

    svg {
        color: $secondary_black;
        fill: $secondary_black;
    }
}

.myinput-textarea-wrapper {
    resize: none;
    background: $secondary_grey_bg;
    padding: 8px !important;
    border-radius: 8px !important;

    &.rtl {
        direction: rtl;
    }

    &.full-width {
        width: 100% !important;
    }
    
    &:focus,
    &:active {
        outline: none !important;
        box-shadow: none !important;
    }

    &[class*="ant-input-borderless"][disabled], 
    &[class*="ant-input"][disabled] {
        background-color: $antd_interactive_bg;
    }
}

.myinput-label {
    position: relative;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: rgba(56, 60, 58, 0.7);
    margin-right: auto;
    margin-top: 8px;
    margin-bottom: 8px;
}