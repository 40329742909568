.teaserTokenList {
  display: flex;
  overflow: auto;
  margin-right: 12px;
  padding-bottom: 4px;
}

.teaserContainer {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
}
