@import 'src/styles/colorVars';

.wrapper {
    background: $primary_grey_bg;
    border: 1px solid $primary_grey_border;
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;

    display: flex;
    flex-direction: column;
    padding: 20px 24px 32px 24px;
    margin-top: 24px;
    margin-bottom: 12px;

    &.rtl {
        direction: rtl;

        .numberText {
            text-align: right;
        }
        .message-preview-switch-block svg {
            transform: rotate(180deg);
        }
    }
}

.numberTitle {
    font-family: SF UI Text;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: $primary_black;
    margin: 0;
}

.numberText {
    margin: 16px 0 12px 0;
    font-family: SF UI Text;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    height: 24px;
    letter-spacing: -0.02em;
    height: 28px;

    color: #000000;
}

.templateText {
    margin: 0;
    font-family: SF UI Text;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.02em;
    white-space: pre-wrap;
    color: #000000;
    direction: rtl;
    text-align: right;
    max-height: 200px;
    overflow-y: auto;
}

.stepsText {
    margin: 0 32px;
    font-family: SF UI Text;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    min-width: 46px;
    display: flex;
    justify-content: center;

    color: #000000;
}

.switchBlock {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 28px;
}

.switchButton {
    width: 76px;
    height: 40px;
    background: $primary_white;
    border: 1px solid $primary_grey_border;
    box-sizing: border-box;
    border-radius: 2px;
    outline: none;
    cursor: pointer;

    &[disabled] {
        cursor: not-allowed;
        background: inherit;

        svg {
            color: $primary_grey;
        }
    }

    &:hover:not([disabled]) svg {
        color: $primary_green !important;
    }
}
