@import 'src/styles/colorVars';

.rich-message-main-block {
    right: 0;

    a {
        color: var(--link-color);

        &:hover,
        &:focus {
            color: var(--link-color-hover);
        }
    }
}

.rich-message-header {
    width: 100%;
    letter-spacing: 0;
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    word-wrap: break-word;
    position: relative;
    margin-top: 4px;
    margin-bottom: 0;
    color: #ffffff;
}

.rich-message-header-image {
    width: 100%;
    position: relative;
    max-height: 180px;
    height: auto;
    width: 100%;
    object-fit: cover;
}

.rich-message-body {
    width: 100%;
    position: relative;
    /*margin-top: 4px;*/
    margin-bottom: 0;
    letter-spacing: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    word-wrap: break-word;
    white-space: pre-wrap;
    color: #ffffff;
    word-break: break-word;
}

.rich-message-footer {
    width: 100%;
    letter-spacing: 0;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    word-wrap: break-word;
    position: relative;
    margin-top: 4px;
    margin-bottom: 0;
    color: #ffffff;

    opacity: 0.7;
}

.rich-message-rtl {
    direction: rtl;
}

.fileDownloadPreview {
    position: relative;
    opacity: 1;

    &:hover {
        div {
            visibility: visible;
            background-color: rgba(0, 0, 0, 0.7);
        }
    }

    img {
        border-radius: 8px;
    }

    div {
        border-radius: 8px;
        visibility: hidden;
        cursor: pointer;
        position: absolute;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $primary_white;
        z-index: 10;
        transition: background-color 0.2s ease;
    }
}
