@import "src/styles/colorVars";

.container {
  min-height: calc(100% - 342px);
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  &.filePreview {
    min-height: calc(100% - 342px + 64px + 66px);
  }

  &.replyOpen:not(&.filePreview) {
    min-height: calc(100% - 388px + 104px);
    max-height: calc(100% - 342px + 104px);
  }
}

.box {
  border: 3px dashed $primary_grey;
  background-color: rgba(253, 253, 253, 0.95);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 12;
  margin: 12px;
}

.inner {
  height: 100%;
  width: 100%;
  text-align: center;
  color: $secondary_black;
  font-size: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.icon svg{
  width: 96px;
  height: 64px;
  margin-bottom: 24px;
}

.text {
  font-family: SF UI Text;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.33px;
  color: #898888 !important;
}
