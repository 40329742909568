@import "src/styles/colorVars";

.file-load-success-main {
  background: #f5f5f5;
  border: 1px solid #eaeaea;
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  min-height: 48px;
  margin: 16px 0;
  padding: 0px 16px;
  width: 100%;

  display: flex;
  align-items: center;
}

.csv-upload-success-info {
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 14px;

  [class="anticon"] {
    align-self: center;
  }
}

.csv-upload-success-text {
  font-family: SF UI Text;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #383c3a;
  margin-bottom: 0;
}

.delete-csv-file {
  cursor: pointer;
}

.text-wrapper {
  display: flex;
  flex-direction: column;

  &.rtl {
    text-align: right;
  }
}

.warning-text {
    color: $primary_yellow;
    font-size: 11px;
}