.block {
    display: grid;
    grid-template-columns: auto auto 1fr auto;
    background-color: white;
    border: 1px solid #EAEAEA;
    border-bottom: none;
    padding: 8px 12px;
    column-gap: 8px;
    max-height: 61px;
}

.title {
    justify-self: end;
}

.icon {
    display: flex !important;
    align-items: center;
}

.avatar {
    width: 18px;
    height: 18px;
    border: 1px solid #FFFFFF;
    border-radius: 50%;
    margin-left: 4px;
}

.departmentColor {
    width: 16px;
    height: 8px;
    border: none;
    border-radius: 2px;
    margin: 0 2px 0 4px;
}

.spacer {
    grid-area: space;
}

.twoLines {
    grid-template-areas:
        ". . . ."
        "space . . space";
}

.text {
    display: flex;
    height: 100%;
    align-items: center;
    margin-left: 12px;
    white-space: nowrap;
    min-width: 0;
}

.withFile {
    align-items: flex-start;
    flex-direction: column;
}

.customReplyText {
    overflow-y: scroll;
    max-height: 44px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.attachedFile {
    max-width: 192px;
    width: fit-content;
    height: 24px;
    background: #F5F5F5;
    border: 1px solid #EAEAEA;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.attachedFileName {
    width: 100%;
    padding: 0 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.labelIcon {
    display: flex !important;
    align-items: center;
    cursor: pointer;
}

.assignedToName {
    margin: 0 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
