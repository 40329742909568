@import 'src/styles/colorVars';
@import 'src/styles/numbers';

.map-wrapper {
    position: relative;
    min-height: 180px;
    min-width: 240px;
    width: 100%;
    margin: 4px 0;

    @media (max-width: $mobile_width) {
        min-height: 150px;
        min-width: 200px;
    }
}

.map {
    position: absolute;
    height: 100%;
    width: 100%;
}

.map-link-button {
    height: 40px !important;
    width: 40px !important;
    position: absolute !important;
    margin: 10px;
    padding: 0 !important;
    z-index: 1;

    &:hover {
        border-color: transparent !important;

        img {
            filter: brightness(50%);
        }
    }

    img {
        width: 18px;
        height: 18px;
    }
}
