@import "src/styles/colorVars";
@import "src/styles/numbers";

.contactListItem {
    display: grid;
    grid-template-columns: 1fr auto;
    width: 100%;
    align-items: center;

    &:hover {
        background-color: $primary_grey_hover;
        cursor: pointer;
    }
}

.contactListItemSelected {
    background: linear-gradient(0deg, #D7F6EA, #D7F6EA), #88B8FF;
}

.contactChosenWrapper {
    width: 32px;

    @media (max-width: $mobile_width) {
        width: 24px;
    }
}

.contactItemIcon {
    svg {
        height: 18px;
        width: 18px;
    }
}

.chatItem {
    max-width: 100%;
    background-color: transparent;

    &:hover {
        background-color: transparent;
    }
}
