@import "src/styles/numbers";
@import "src/styles/colorVars";

.blockerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 16px;
    text-align: center;
}

.blockerInnerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.blockerImage {
    height: 64px;
    width: 64px;
    fill: $tertiary_black;

    @media (max-width: $mobile_width) {
        height: 48px;
        width: 48px;
    }
}

.blockerTitle {
    margin-top: 25px;
    margin-bottom: 0;
    font-family: SF UI Text,"Segoe UI";
    font-size: 16px;
    line-height: 24px;
    color: $primary_black;
}

.blockerText {
    margin-top: 25px;
    margin-bottom: 0;
    font-family: SF UI Text,"Segoe UI";
    font-size: 12px;
    line-height: 18px;
    color: $primary_black;
    opacity: 0.7;
}

.blockerButton {
    height: 40px !important;
    margin-top: 32px;
}
