@import "src/styles/numbers";

.chatNotesContainer {
  display: flex;
  flex-direction: column;
  margin: 0 24px;

  @media (max-width: $mobile_width) {
    margin: 0;
    padding: 0 12px;
  }
}

