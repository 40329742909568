@import "src/styles/colorVars";

.wizard-warning-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    padding: 24px;
    border-bottom: 1px solid #dddddd;
}

.bulkWizard {
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.4;
    letter-spacing: -0.02em;
    margin: 0;
    color: #4C5250;
}

.loading-icon {
    font-size: 48px;
    width: 48px;
    height: 48px;
    color: $primary_green !important;
    margin-bottom: 16px;
}

.loading__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 28px;
}

.container {
    display: grid;
    grid-template-rows: auto 1fr;
    height: 100%;
    overflow-y: auto;
}
