@import "src/styles/colorVars";
@import "src/styles/numbers";

.message-drawer__container {
  display: grid;
  align-items: center;
  height: calc(100vh - #{$sidebar_width});
  overflow-y: hidden;
}

.message-drawer__back {
  display: grid;
  align-items: center;
  grid-template-columns: max-content max-content;
  justify-self: flex-start;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  margin: 16px;
  line-height: 45px;

  &.rtl { 
    svg {
      transform: rotate(180deg);
    }
  }
}

.message-drawer__icon {
  color: #bfbdbd;
  font-size: 80px !important;
  margin-bottom: 20px;
}

.message-drawer__header {
  display: grid;
  justify-items: center;
  font-size: 20px;
  font-weight: 400;
}

.message-drawer__content {
  padding: 20px 60px;
  color: $tertiary_black;
  position: relative;
}

.message-drawer {
  .MuiDrawer-paperAnchorLeft {
    margin-left: 65px;
    width: 420px;
  }

  &.rtl {
    .MuiDrawer-paperAnchorRight {
      margin-right: 65px;
    }
  }
}

.message-drawer .MuiDivider-root {
  width: 260px;
}
.message-drawer .MuiIcon-fontSizeLarge {
  font-size: 150px;
}
.message-drawer__input {
  font-size: 18px !important;
}
.message-drawer__input-title {
  display: grid;
  grid-template-columns: 1fr max-content;
  align-items: center;
  font-size: 12px;
  margin-bottom: 4px;
}

.message-drawer input:focus {
  outline: none;
}

.message-drawer__error {
  color: $primary_red;
  font-size: 10px;
}

.message-drawer__button {
  width: 100%;
  margin-left: 0;
  margin-top: 16px;
}

.message-drawer__box {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.message-drawer__moreInfo {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  min-height: 50px;

  &.rtl {
      text-align: right;
      direction: rtl;

      .info-circle {
          margin-left: 8px;
          margin-right: 0;
      }
  }
}

.message-placeholder {
  height: 66px;
}

.info-text {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 1.4;
  letter-spacing: -0.02em;
  margin: 0;
  color: $tertiary_black;
}

.info-circle {
  margin-right: 7px;
  margin-top: 2px;
}

.message-drawer__channelSelect {
  [class="ant-select-selector"] {
    padding: 0 8px !important;
    border-top: none !important;
    max-width: 300px;
  }


  [class="ant-select-arrow"] {
    right: 8px;
  }
}

.message-drawer__messengerSelect {
  [class="ant-select-selector"] {
    padding: 0 8px !important;
    height: 40px !important;
    width: 100% !important;
  }
  [class="ant-select-selection-placeholder"] {
    display: flex;
    align-items: center;
  }
  input {
    height: 38px !important;
  }
}

@media (max-width: $mobile_width) {
  .message-drawer .MuiDrawer-paperAnchorLeft {
    width: 100%;
    margin-left: 0px;
  }
  .message-drawer__content {
    padding: 20px;
  }
}
