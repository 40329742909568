.label {
  display: flex;
  margin: 5px 0;
  justify-content: space-between;
  font-family: SF UI Text;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: rgba(56, 60, 58, 0.7);
}

.label:first-child {
  text-transform: capitalize;
}
