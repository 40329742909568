.modal {
    [class='ant-modal-content'] {
        background-color: transparent;
        box-shadow: none;
    }
}

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin: 0 auto;
    box-shadow: 0 3px 6px -4px rgba(0,0,0,.12), 0 6px 16px 0 rgba(0,0,0,.08), 0 9px 28px 8px rgba(0,0,0,.05);
    border-radius: 2px;

    video {
        height: auto;
        max-height: 75vh;
        width: 100%;
    }
}
