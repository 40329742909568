@import "src/styles/colorVars";

.addReactionContainer {
    display: none;
    align-items: center;
    position: absolute;
    left: -36px;
    bottom: 0;
    padding: 4px;
    height: 100%;
    z-index: 100;

    &.incoming {
        left: auto;
        right: -36px;
    }
}

.addReactionButton {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: $tertiary_black;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        width: 16px;
        height: 16px;
        fill: $primary_white;
    }

    &:hover {
        background-color: $secondary_black;
    }
}

.portalContainer {
    position: fixed;
}