.call-to-action-buttons-block {
    border-top: 0.5px solid white;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 4px;
}

.call-to-action-button {
    background: var(--background-color, #47B972);
    border: none;
    height: 32px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4px;
}

.call-to-action-button img {
    width: 12px;
    height: 12px;
}

.call-to-action-button-image {
    margin-top: 5px;
    width: 10px;
    height: 10px;

    filter: invert(69%) sepia(89%) saturate(3688%) hue-rotate(171deg) brightness(99%) contrast(101%);
}

.call-to-action-button-text {
    letter-spacing: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    word-wrap: break-word;
    position: relative;
    margin-top: 4px;
    margin-bottom: 0;
    color: #ffffff;
}

.call-to-action-button-image + .call-to-action-button-text {
    margin-left: 2px;
}
