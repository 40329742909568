@import "../../../../styles/colorVars.scss";

.simpleModalContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80vh;
}

.simpleModalHeader {
  height: 80px;
  min-height: 80px;
  width: 100%;
  border-bottom: 2px solid $primary_grey;
  padding: 0 24px;

  letter-spacing: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  word-wrap: break-word;
  position: relative;
  margin-top: 0;
  margin-bottom: 0;
  color: #353535;

  display: flex;
  align-items: center;
  justify-content: space-between;

  &.rtl {
    flex-direction: row-reverse;
  }
}

.simpleModalTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.simpleModalTitleIcon {
  height: 16px;
  width: 16px;
  margin-right: 16px;
}

.simpleModalGreenUnderline svg{
  fill: #30C498;
}

.simpleModalGreenUnderline::after {
  content: '';
  border-bottom: 2px solid #30C498;
  width: 56px;
  left: 25px;
  position: absolute;
  display: block;
  top: 78px;
}

.simpleModalRedUnderline svg{
  fill: #E26565;
}

.simpleModalRedUnderline::after {
  content: '';
  border-bottom: 2px solid #E26565;
  width: 56px;
  left: 25px;
  position: absolute;
  display: block;
  top: 78px;
}

.closeIconWrapper {
  width: 32px;
  height: 32px;
  border-radius: 2px;
  text-align: center;
  line-height: 32px;
  transition: 500ms;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.closeIconWrapper:hover {
  background: #F5F5F5;
}

.content {
  height: 100%;
  display: flex;
  overflow-y: auto;
}

.centerContent {
  align-items: center;
}
