@import 'src/styles/colorVars';
@import 'src/styles/numbers';

.message-drawer__paperAnchorLeft {
    margin-left: $sidebar_width;
    width: 100%;
    max-width: 768px;
    color: $primary_black;
    overflow-y: hidden;

    @media (max-width: $mobile-width) {
        margin-left: 0px;
        max-width: 100vw;
    }
}

.editProfileContainer {
    display: grid;
    grid-template-columns: 7fr 6fr;
    align-items: center;
    height: 100vh;
    overflow: hidden;

    @media (max-width: $mobile-width) {
        grid-template-columns: 1fr;
        overflow: auto;
        height: 100%;
    }
}

.gridItem {
    background-color: $primary_white;
    height: 100vh;
    border-right: 2px solid $primary_grey_border;
    display: grid !important;
    grid-template-rows: auto 1fr;

    @media (max-width: $mobile-width) {
        height: auto;
    }
}

/* FORM */

.comment {
    display: block;
    font-style: italic;
    color: $secondary_black;
    margin-top: 5px;
    margin-bottom: 16px;
}

.FormBlock {
    label {
        font-size: 14px;
        font-weight: 600;
        display: inline;
    }

    input {
        padding: 14px 14px 14px 20px;

        &::placeholder {
            font-style: italic;
        }
    }

    textarea {
        padding: 14px;
    }

    select {
        padding: 12px 12px 12px 20px;
    }
}

.FormBlockArray {
    & + & {
        margin-top: 12px;
    }
}

.upperComment {
    float: right;
    margin-top: -32px;
    color: $secondary_black;
}

.summaryCol {
    padding: 32px;
    text-align: center;
    display: flex !important;
    flex-direction: column;
    overflow-y: auto;

    @media (max-width: $mobile-width) {
        border-top: 1px solid $primary_grey_border;
        overflow-y: unset;
    }
}

.formCol {
    margin-bottom: 24px;
}

.emptyImage {
    margin-left: auto;
    margin-right: auto;
    background: $secondary_grey;
    border-radius: 50%;
    width: 96px;
    height: 96px;
    text-align: center;
    font-size: 40px;
    color: $primary_grey;
    line-height: 96px;
}

.imagePreview {
    margin-left: auto;
    margin-right: auto;
    background: $secondary_grey;
    border-radius: 50%;
    width: 96px;
    height: 96px;
    text-align: center;
    font-size: 40px;
    color: $primary_grey;
    line-height: 96px;
    background-size: cover;
    background-position: center center;
}

.valuesPreview {
    margin: 40px 0;
    text-align: left;
    font-style: italic;
    flex-wrap: nowrap;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;

    span {
        margin-left: 20px;
    }
}

.emptyValue {
    color: $tertiary_black;
    font-style: italic;
    margin-top: 10px;
    display: flex;
    align-content: center;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
}

.filledValue {
    margin-top: 10px;
    display: flex;
    align-content: center;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;

    svg {
        color: $primary_green;
        width: 14px;
        min-width: 14px;
        height: 14px;
    }

    span {
        color: $primary_black;
        font-style: normal;
    }
}

.title {
    font-size: 18px;
    margin-top: 20px;
    display: inline-block;
    font-weight: 700;
}

.emptyTitle {
    margin-top: 20px;
    font-style: italic;
    display: inline-block;
    font-size: 18px;
    font-weight: 300;
}

.category {
    display: block;
    color: $tertiary_black;
    font-size: 14px;
    margin-top: 10px;
}

.editProfileLoadingContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100% - #{$sidebar_width});
    position: relative;
    left: -20px;
}

.editProfileBack {
    display: grid;
    align-items: center;
    grid-template-columns: max-content max-content;
    justify-self: flex-start;
    font-size: 16px;
    font-weight: 600;
    line-height: 45px;

    svg {
        cursor: pointer;
    }
}
.headerRow {
    position: sticky;
    top: 0;
    background-color: $primary_white;
    z-index: 10;
    padding: 0 32px;
    padding-top: 32px;
    margin-bottom: 24px;
}

.contentRow {
    overflow: auto;
    padding: 0 32px;
    height: 100%;
    background: linear-gradient(white 30%, rgba(255, 255, 255, 0)),
        linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
        radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0)),
        radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0)) 0 100%;
    background-repeat: no-repeat;
    background-color: white;
    background-size: 100% 40px, 100% 40px, 100% 6px, 100% 6px;
    background-attachment: local, local, scroll, scroll;
}

.formContainer {
    [class*='ant-form-item'] {
        label {
            font-size: 14px;
            font-weight: 600;
            display: inline;
        }

        input {
            padding: 4px 0px !important;

            &::placeholder {
                font-style: italic;
            }
        }

        textarea {
            padding: 14px;
        }

        select {
            padding: 12px 12px 12px 20px;
        }

        [class='ant-select-selector'] {
            height: 40px !important;
        }

        [class='ant-form-item-extra'] {
            display: block;
            font-style: italic;
            color: $secondary_black;
            margin-top: 5px;
        }

        [class*='array-item-list'] {
            & > div {
                flex-direction: column;
                [class='ant-col'] {
                    width: 100%;
                    flex: none !important;

                    [class='ant-form-item'] {
                        margin-bottom: 8px;
                    }

                    [class='ant-btn-group'] {
                        justify-content: flex-end;
                    }
                }
            }

            [class='ant-row ant-form-item'] {
                margin-bottom: 8px !important;
            }

            [class='ant-row ant-row-top'] {
                margin-bottom: 16px;
            }

            [class*='ant-form-item-label'] {
                display: none;
            }
         }

        fieldset {
          [class='ant-row ant-row-end'] {
              [class='ant-col'] {
                  flex: 0 0 100% !important;
              }
          }
        }
    }

    button[type='submit'] {
        display: none;
    }

    @media (max-width: $mobile-width) {
        overflow: hidden;
    }
}

.editProfileHeader {
    display: grid;
    justify-items: center;
    font-size: 20px;
    font-weight: 400;
}

.editProfileTitle {
    margin-left: -12px;
}

.editProfileButtons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 8px;
    margin-top: auto;
}

.editProfileButton {
    min-width: 100px;
    height: 40px !important;
    min-height: 40px !important;
    display: flex !important;
    border-radius: 4px !important;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &:hover {
        border-color: $primary_green !important;
        box-shadow: none !important;
    }

    &.defaultButton {
        &:hover {
            color: $primary_green !important;
        }
    }
}

.verifiedBadgeContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 64px;
}

.verifiedBadgeButton {
    text-transform: capitalize;
    flex-direction: row;

    svg {
        width: 16px;
        height: 16px;
        margin-right: 8px;
    }
}

.verifiedBadgeIcon {
    align-self: center;
    margin-bottom: 24px;

    svg {
        width: 40px;
        height: 40px;
    }
}

.verifiedBadgeMessage {
    margin-bottom: 24px;
    font-size: 15px;
    line-height: 21px;
}

.avatarContainer {
    width: 100%;
    height: auto;
    max-height: 100%;
    object-fit: cover;
}