@import "../../../../../styles/colorVars.scss";

.sendAppLinksWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
    
    &.rtl {
      text-align: right;
    }
}
  
.sendAppLinksFormWrapper {
    display: flex;
    flex-direction: row;
    gap: 12px;
  
    &.rtl {
      direction: rtl;
    }
}
  
.phoneInputError {
    color: $primary_red;
    font-size: 11px;
}

.errorMessage {
    color: $primary_red;
    font-size: 11px;
}

.sendAppLinksButton {
  width: 96px;

  &:not([disabled]):hover {
    color: $primary_white !important;
  }
}