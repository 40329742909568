@import "src/styles/colorVars";

.topBar {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: var(--partner-theme-color, $primary_blue);
  height: 46px;
  align-items: center;
  width: 100%;
  position: fixed;
  padding: 12px 16px;
}

.image {
  height: 24px;
  max-height: 24px;
  border-radius: 4px;
}

.topBarIcon {
  justify-self: end;
  color: white;
  width: 24px;
  height: 24px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  font-size: 18px !important;
}

.topBarIcon svg {
  fill: white !important;
}

.topBarIconCross {
  transform: rotate(45deg);
  font-size: 22px !important;
}

.top .MuiDrawer-paperAnchorLeft {
  width: 100vw;
  height: 100vh;
  background: #546BD6;
}

.top .MuiDivider-root {
  width: 260px;
  margin-left: 0 !important;
}

@media (min-width: 1026px) {
  .topBar {
    display: none;
  }
}
