@import "src/styles/colorVars";
@import "src/styles/numbers";

.template {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-left: transparent 2px solid;
  padding: 8px 24px;

  &.inbox {
    padding: 8px 12px;
    min-width: 0;

    .title {
      word-break: keep-all;
    }
    .content {
      min-width: 16rem;
    }
  }

  @media (max-width: $mobile-width) {
    flex-direction: column;
    align-items: center;
    padding: 8px;
  }
}

.hoverable:hover {
  cursor: pointer;
  background: $primary_grey_bg;
  border-left: $primary_green 2px solid;
}

.greenBackground {
  cursor: pointer;
  background-color: $primary_green_bg;
}

.title {
  font-size: 16px;
  font-weight: 500;
  color: $primary_black;
  margin-bottom: 13px;
  display: flex;
  flex-direction: column;

  &.rtl {
    text-align: right;
  }

  @media (max-width: $mobile-width) {
    width: 100%;
  }
}

.subtitle {
  font-size: 12px;
  line-height: 14px;
  color: $secondary_black !important;
  word-break: break-all !important;
}

.categoryTag {
  width: fit-content;
  font-size: 10px !important;
  margin-top: 6px !important;
}

.text {
  direction: rtl;
  text-align: right;
  font-size: 14px;
  overflow-wrap: break-word;
  white-space: pre-wrap;
}

.image {
  width: auto;
  height: 100px;
  object-fit: cover;
}

.content {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  width: 16rem;
  padding: 0 8px;
}
