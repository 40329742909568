.buttonWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 40px 0;
}

.button {
  height: 48px !important;
  min-width: 168px;
  background-color: var(--primary-green);
  color: #FFFFFF;
  border-radius: 2px;
  cursor: pointer;

  font-family: SF UI Text,"Segoe UI";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;

  border-color: var(--primary-green) !important;
}

.button:focus {
  background-color: var(--primary-green) !important;
  border: transparent;
}

.button:hover {
  background-color: #00AB66;
  color: white;
  border: transparent;
}
