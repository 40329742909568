.divider {
    width: 100%;
    height: 1px;
    background: #ddd;
}

.empty {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 100px;
}
