@import "src/styles/colorVars";

.scroll-button-container {
    height: 50px;
    width: 50px;
    border: none;
    position: absolute;
    bottom: 8px;
    right: 8px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    color: $primary_white;

    &.rtl {
        right: unset;
        left: 8px;
    }
}

.unread-counter {
    position: absolute;
    left: 2rem;
    top: 2px;
    background-color: var(--primary-color);
    border: 1px solid $primary_white;
    background-color: var(--primary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 20px;
    width: 20px;
}

.unread-counter-number {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    margin: 0;
    padding: 2px;
    color: $primary_white;
}

.scroll-button {
    border-radius: 50%;
    background-color: var(--primary-color);
    border: 1px solid $primary_white;
    height: 35px;
    width: 35px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.scroll-down-arrow {
    margin-top: 2px;
    transform: rotate(90deg);
}
